export default ({
  "Celé Slovensko": { code: 1, acronym: "CS", location: { lat: 48.770866, lng: 19.642608 }, displayValue: "Celé Slovensko"},
  "Banská Bystrica": { code: 601, acronym: "BB", location: { lat: 48.736277, lng: 19.1461917 }, displayValue: "Banská Bystrica"},
  "Banská Štiavnica": { code: 602, acronym: "BS", location: { lat: 48.44957600000001, lng: 18.9086908 }, displayValue: "Banská Štiavnica"},
  "Bardejov": { code: 701, acronym: "BJ", location: { lat: 49.2945857, lng: 21.2754012 }, displayValue: "Bardejov"},
  "Bánovce nad Bebravou": { code: 301, acronym: "BN", location: { lat: 48.71884439999999, lng: 18.2603402 }, displayValue: "Bánovce nad Bebravou"},
  "Bratislava": { code: 101, acronym: "B1", location: { lat: 48.1485965, lng: 17.1077478 }, displayValue: "Bratislava - všetky okresy"},
  "Brezno": { code: 603, acronym: "BR", location: { lat: 48.8060729, lng: 19.6438178 }, displayValue: "Brezno"},
  "Bytča": { code: 501, acronym: "BY", location: { lat: 49.22252049999999, lng: 18.5588046 }, displayValue: "Bytča"},
  "Čadca": { code: 502, acronym: "CA", location: { lat: 49.43855509999999, lng: 18.7897828 }, displayValue: "Čadca"},
  "Detva": { code: 604, acronym: "DT", location: { lat: 48.5587115, lng: 19.4183349 }, displayValue: "Detva"},
  "Dolný Kubín": { code: 503, acronym: "DK", location: { lat: 49.21267899999999, lng: 19.2968746 }, displayValue: "Dolný Kubín"},
  "Dunajská Streda": { code: 201, acronym: "DS", location: { lat: 47.9916348, lng: 17.6206306 }, displayValue: "Dunajská Streda"},
  "Galanta": { code: 202, acronym: "GA", location: { lat: 48.18954129999999, lng: 17.7266636 }, displayValue: "Galanta"},
  "Gelnica": { code: 801, acronym: "GL", location: { lat: 48.8518087, lng: 20.933943 }, displayValue: "Gelnica"},
  "Hlohovec": { code: 203, acronym: "HC", location: { lat: 48.4251583, lng: 17.8033288 }, displayValue: "Hlohovec"},
  "Humenné": { code: 702, acronym: "HE", location: { lat: 48.932454, lng: 21.9078917 }, displayValue: "Humenné"},
  "Ilava": { code: 302, acronym: "IL", location: { lat: 48.9962271, lng: 18.2332775 }, displayValue: "Ilava"},
  "Kežmarok": { code: 703, acronym: "KK", location: { lat: 49.1332664, lng: 20.4306967 }, displayValue: "Kežmarok"},
  "Komárno": { code: 401, acronym: "KN", location: { lat: 47.7625785, lng: 18.1294132 }, displayValue: "Komárno"},
  "Košice": { code: 802, acronym: "K1", location: { lat: 48.7163857, lng: 21.2610746 }, displayValue: "Košice - všetky okresy"},
  "Krupina": { code: 605, acronym: "KA", location: { lat: 48.3518392, lng: 19.0670496 }, displayValue: "Krupina"},
  "Kysucké Nové Mesto": { code: 504, acronym: "KM", location: { lat: 49.3006944, lng: 18.7842828 }, displayValue: "Kysucké Nové Mesto"},
  "Levice": { code: 402, acronym: "LV", location: { lat: 48.217424, lng: 18.5984375 }, displayValue: "Levice"},
  "Levoča": { code: 704, acronym: "LE", location: { lat: 49.0202097, lng: 20.5843112 }, displayValue: "Levoča"},
  "Liptovský Mikuláš": { code: 505, acronym: "LM", location: { lat: 49.0811487, lng: 19.6192067 }, displayValue: "Liptovský Mikuláš"},
  "Lučenec": { code: 606, acronym: "LC", location: { lat: 48.3286673, lng: 19.6690601 }, displayValue: "Lučenec"},
  "Malacky": { code: 106, acronym: "MA", location: { lat: 48.4363404, lng: 17.0182641 }, displayValue: "Malacky"},
  "Martin": { code: 506, acronym: "MT", location: { lat: 49.0616613, lng: 18.9190235 }, displayValue: "Martin"},
  "Medzilaborce": { code: 705, acronym: "ML", location: { lat: 49.27483489999999, lng: 21.9065029 }, displayValue: "Medzilaborce"},
  "Michalovce": { code: 807, acronym: "MI", location: { lat: 48.7556769, lng: 21.9183858 }, displayValue: "Michalovce"},
  "Myjava": { code: 303, acronym: "MY", location: { lat: 48.7552968, lng: 17.5674635 }, displayValue: "Myjava"},
  "Námestovo": { code: 507, acronym: "NO", location: { lat: 49.404472, lng: 19.4812155 }, displayValue: "Námestovo"},
  "Nitra": { code: 403, acronym: "NR", location: { lat: 48.3061414, lng: 18.076376 }, displayValue: "Nitra"},
  "Nové Mesto nad Váhom": { code: 304, acronym: "NM", location: { lat: 48.7568395, lng: 17.8253958 }, displayValue: "Nové Mesto nad Váhom" },
  "Nové Zámky": { code: 404, acronym: "NZ", location: { lat: 47.9881643, lng: 18.1571098 }, displayValue: "Nové Zámky"},
  "Partizánske": { code: 305, acronym: "PE", location: { lat: 48.628493, lng: 18.3768911 }, displayValue: "Partizánske"},
  "Pezinok": { code: 107, acronym: "PK", location: { lat: 48.2891353, lng: 17.2666557 }, displayValue: "Pezinok"},
  "Piešťany": { code: 204, acronym: "PN", location: { lat: 48.5917973, lng: 17.827155 }, displayValue: "Piešťany"},
  "Poltár": { code: 607, acronym: "PT", location: { lat: 48.4295455, lng: 19.7947739 }, displayValue: "Poltár"},
  "Poprad": { code: 706, acronym: "PP", location: { lat: 49.0511221, lng: 20.295414 }, displayValue: "Poprad"},
  "Považská Bystrica": { code: 306, acronym: "PB", location: { lat: 49.1131198, lng: 18.447663 }, displayValue: "Považská Bystrica"},
  "Prešov": { code: 707, acronym: "PO", location: { lat: 49.0018324, lng: 21.2393119 }, displayValue: "Prešov" },
  "Prievidza": { code: 307, acronym: "PD", location: { lat: 48.7745206, lng: 18.6245384 }, displayValue: "Prievidza"},
  "Púchov": { code: 308, acronym: "PU", location: { lat: 49.1235654, lng: 18.3241203 }, displayValue: "Púchov"},
  "Revúca": { code: 608, acronym: "RA", location: { lat: 48.6834697, lng: 20.1138148 }, displayValue: "Revúca"},
  "Rimavská Sobota": { code: 609, acronym: "RS", location: { lat: 48.3812295, lng: 20.0195452 }, displayValue: "Rimavská Sobota"},
  "Rožňava": { code: 808, acronym: "RV", location: { lat: 48.6561405, lng: 20.5323841 }, displayValue: "Rožňava"},
  "Ružomberok": { code: 508, acronym: "RK", location: { lat: 49.0752393, lng: 19.3066002 }, displayValue: "Ružomberok"},
  "Sabinov": { code: 708, acronym: "SB", location: { lat: 49.10276959999999, lng: 21.0978 }, displayValue: "Sabinov"},
  "Senec": { code: 108, acronym: "SC", location: { lat: 48.2170352, lng: 17.4061427 }, displayValue: "Senec"},
  "Senica": { code: 205, acronym: "SE", location: { lat: 48.6798645, lng: 17.3610002 }, displayValue: "Senica"},
  "Skalica": { code: 206, acronym: "SI", location: { lat: 48.8448118, lng: 17.2250302 }, displayValue: "Skalica"},
  "Snina": { code: 709, acronym: "SV", location: { lat: 48.987657, lng: 22.1583021 }, displayValue: "Snina"},
  "Sobrance": { code: 809, acronym: "SO", location: { lat: 48.74576889999999, lng: 22.1794779 }, displayValue: "Sobrance"},
  "Spišská Nová Ves": { code: 810, acronym: "SN", location: { lat: 48.9452771, lng: 20.563649 }, displayValue: "Spišská Nová Ves"},
  "Stará Ľubovňa": { code: 710, acronym: "SL", location: { lat: 49.3010575, lng: 20.6898463 }, displayValue: "Stará Ľubovňa"},
  "Stropkov": { code: 711, acronym: "SP", location: { lat: 49.2026115, lng: 21.6518049 }, displayValue: "Stropkov"},
  "Svidník": { code: 712, acronym: "SK", location: { lat: 49.3055641, lng: 21.568117 }, displayValue: "Svidník"},
  "Šaľa": { code: 405, acronym: "SA", location: { lat: 48.1513924, lng: 17.8756083 }, displayValue: "Šaľa"},
  "Topoľčany": { code: 406, acronym: "TO", location: { lat: 48.5589454, lng: 18.1700065 }, displayValue: "Topoľčany"},
  "Trebišov": { code: 811, acronym: "TV", location: { lat: 48.6284913, lng: 21.715555 }, displayValue: "Trebišov"},
  "Trenčín": { code: 309, acronym: "TN", location: { lat: 48.884936, lng: 18.0335208 }, displayValue: "Trenčín"},
  "Trnava": { code: 207, acronym: "TT", location: { lat: 48.3943898, lng: 17.7216205 }, displayValue: "Trnava"},
  "Turčianske Teplice": { code: 509, acronym: "TR", location: { lat: 48.8637142, lng: 18.8594751 }, displayValue: "Turčianske Teplice"},
  "Tvrdošín": { code: 510, acronym: "TS", location: { lat: 49.3335213, lng: 19.5548296 }, displayValue: "Tvrdošín"},
  "Veľký Krtíš": { code: 610, acronym: "VK", location: { lat: 48.20835080000001, lng: 19.3507599 }, displayValue: "Veľký Krtíš"},
  "Vranov nad Topľou": { code: 713, acronym: "VT", location: { lat: 48.8904721, lng: 21.6825245 }, displayValue: "Vranov nad Topľou"},
  "Zlaté Moravce": { code: 407, acronym: "ZM", location: { lat: 48.3851703, lng: 18.3912685 }, displayValue: "Zlaté Moravce"},
  "Zvolen": { code: 611, acronym: "ZV", location: { lat: 48.5761806, lng: 19.1371155 }, displayValue: "Zvolen"},
  "Žarnovica": { code: 612, acronym: "ZC", location: { lat: 48.48334870000001, lng: 18.7217034 }, displayValue: "Žarnovica"},
  "Žiar nad Hronom": { code: 613, acronym: "ZH", location: { lat: 48.5905012, lng: 18.8548946 }, displayValue: "Žiar nad Hronom"},
  "Žilina": { code: 511, acronym: "ZA", location: { lat: 49.21944980000001, lng: 18.7408001 }, displayValue: "Žilina"},
});