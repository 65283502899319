export default {
  language: "Sprache",
  cancel: "Abbrechen",
  popup: {
    password: "Passwort",
    cancel: "Abbrechen",
    confirm: "Bestätigen"
  },
  data: {
    myRegion: "Meine Region",
    allRegions: "Alle Regionen",
    noData: "Es gibt leider keine Ergebnisse zu deiner Suchanfrage",
  },
  errors: {
    errorTitle: "Error",
    back: "Zurück zur Startseite",
    form: "Das Formular wurde leider falsch ausgefüllt",
    address: "Ungültige Adresse",
    atLeastOneDay: "Bitte wähle mindestens einen Tag aus",
    errorText: "Entschuldigung, es ist ein Fehler aufgetreten und wir konnten deine Anfrage nicht bearbeiten. Bitte versuche es später erneut. Wenn dieser Fehler weiterhin besteht, schick uns eine Email an helfni@helfni.sk."
  },
  activeEmail: {
    text: "Deine E-Mail-Adresse ist bestätigt! Du kannst dich nun",
    login: "einloggen",
    invalidCode: "Der Code ist ungültig. Um dein Konto zu aktivieren, nutze bitte den Link aus unserer Email.",
    alreadyConfirmed: "Deine E-Mail-Adresse wurde bereits bestätigt, du kannst dich nun einloggen."
  },
  login: {
    confirm: "Bitte bestätige deine E-Mail-Adresse über den Link, den wir an deine E-Mail-Adresse gesendet haben. Nach der Bestätigung kannst du dich dann anmelden. Falls die Email nicht sofort ankommt, warte bitte einige Minuten und schau auch in deinem Spam-Ordner nach. Falls die Email nach einiger Zeit immer noch nicht ankommt, schick uns eine Email an perhelfni@helfni.sk.",
    register: "Um Helfer zu sehen, musst du dich zunächst als Organisation registrieren und anmelden.",
    welcome: "Willkommen!",
    username: "Benutzername oder E-Mail-Adresse",
    password: "Passwort",
    forgot: "Passwort vergessen?",
    login: "Einloggen",
    noAccount: "Du hast dich noch nicht registriert?",
    createAccount: "Erstelle ein neues Konto",
    incorrectPassword: "Ungültiges Passwort",
    userNotFound: "Ungültiger Benutzername oder E-Mail-Adresse",
    emailNotVerified: "Bitte bestätige deine E-Mail-Adresse mit dem Link, den wir dir per Email geschickt haben."
  },
  passwordRecovery: {
    recover: "Passwort zurücksetzen",
    enterNewPassword: "Gib hier dein neues Passwort ein",
    newPassword: "Neues Passwort",
    changePassword: "Passwort ändern",
    codeUsed: "Du hast bereits ein neues Passwort angefordert.",
    invalidCode: "Ungültiger Code",
    inputEmail: "Bitte gebe deine E-Mail-Adresse an und wir schicken dir einen Link zu deinem neuen Passwort.",
    email: "Email",
    messageSent: "Falls die angegebene E-Mail-Adresse korrekt war, solltest du jetzt eine Email erhalten um dein Passwort zurückzusetzen. Bitte warte ein paar Minuten, falls die Email noch nicht angekommen ist und schau auch besser mal in deinen Spam-Ordner. Falls die Email nicht ankommen sollte, erreichst du uns unter helfni@helfni.sk.",
    sendEmail: "Passwort zurücksetzen",
    success: "Dein Passwort wurde zurückgesetzt. Du kannst dich jetzt",
    login: "einloggen"
  },
  register: {
    title: "Registriere dich bei HELFNI und fang an zu helfen!",
    accountType: {
      title: "Bist du ein Helfer oder eine Organisation?",
      volunteer: "Helfer",
      volunteerDescription: "Als Helfer kannst du Angebote von Organisationen finden und auswählen.",
      organisation: "Organisation",
      organisationDescription: "Als Organisation kannst du Angebote erstellen, auf die sich Hilfswillige bewerben können."
    },
    continue: "Weiter",
    registration: "Registrieren",
    back: "Zurück",
    personalInformation: {
      title: "Persönliche Informationen",
      name: "Vor- und Nachname",
      email: "E-Mail-Adresse",
      region: "Meine Region",
      descriptionText: "Schreibe etwas über dich! Hast du bereits Erfahrungen? Was motiviert dich? Oder irgendetwas anderes, damit dich die Organisationen ein bisschen kennenlernen können.",
      descriptionLabel: "Schreibe etwas über dich und deine Erfahrungen",
      skills: "Meine Fähigkeiten und Wissen"
    },
    organisation: {
      title: "Informationen über die Organisation",
      name: "Name der Organisation",
      address: "Adresse",
      region: "Region",
      description: "Beschreibe deine Organisation",
      contactTitle: "Kontaktdaten",
      phone: "Telefonnummer",
      email: "E-Mail-Adresse"
    },
    loginDetails: {
      title: "Login Daten",
      username: "Benutzername",
      password: "Passwort",
      repeatPassword: "Passwort wiederholen"
    },
    consent: "Ich akzeptiere die",
    terms: "Nutzerbedingungen",
    register: "Registrieren",
    errors: {
      usernameTaken: "Dieser Benutzername ist bereits vergeben",
      emailTaken: "Diese E-Mail-Adresse hat bereits ein Konto",
      fixErrors: "Bitte fülle die rot markierten Felder aus"
    }
  },
  navbar: {
    dashboard: "Startseite",
    home: "Startseite",
    offers: "Angebote",
    map: "Karte",
    volunteers: "Helfer",
    organisations: "Organisationen",
    logout: "Ausloggen",
    login: "Einloggen",
    register: "Registrieren",
    profile: "Profil"
  },
  landing: {
    title: "deine Stadt",
    subtitle: "brauch dich mehr denn je!",
    wantToHelp: "Möchtest du helfen?",
    seeOffers: "Angebote",
    howToHelp: {
      title: "Wie kannst du mit HELFNI helfen?",
      register: "Registriere dich online oder in unserer App",
      choose: "Such nach Angeboten oder erstelle welche",
      help: "Hinterlasse deinen Fußabdruck",
    },
    iWantToRegister: "Registrieren",
    whoWeAre: {
      title: "Wer sind wir?",
      text: "{kajo} steckt hinter den Szenen oder eher gesagt hinter den Codes. Die ursprüngliche HILFNI Idee ist jedoch von einer Studentengruppe aus Košice (Slowakei), die von dem Society Development Institute und der Green Foundation unterstützt wurde.  "
    },
    whatWeDo: {
      title: "Was machen wir?",
      text: "{helfni} ist eine Plattform, die die Kommunikation zwischen Freiwilligen und Organisationen verbessern möchte, um Helfer schneller mit öffentlichen und privaten Organisationen zu vermitteln. In diesen Zeiten ist Hilfe äußerst wertvoll!"
    },
    numbers: {
      title: "Wir schaffen das ZUSAMMEN",
      volunteers: "Helfer",
      organisations: "Organisationen",
      offers: "Angebote"
    },
    offerList: {
      title: "Hilf uns",
      notFound: "Es wurden keine Angebote gefunden",
      showMore: "mehr"
    },
    map: {
      title: "Wo kannst du uns finden?",
      showOnMap: "Auf der Karte anzeigen"
    },
    motto: "HELFNI - wir helfen um zu helfen",
    partners: "Wir arbeiten mit diesen Organisationen zusammen",
    footer: {
      socialMedia: "Du findest uns auch auf in den sozialen Medien",
      disclaimer: "Die von dieser Anwendung bereitgestellten Informationen sind unzensiert. Jeder registrierte Benutzer ist allein verantwortlich für den Inhalt, die Wahrhaftigkeit und Aktualität der Informationen liegt ausschließlich bei jedem registrierten Benutzer."
    }
  },
  event: {
    back: "Zurück",
    create: "Gestalte dein eigenes Angebot",
    delete: "Lösche dein Angebot",
    cancel: "Stornieren",
    gallery: "Galerie der Veranstaltung",
    map: "Karte",
    errors: {
      endBeforeStart: "Die Endzeit ist vor der Startzeit",
      invalidAddress: "Es ist ein Problem mit der Adresse aufgetreten. Bitte versuche diese in einem anderen Format anzugeben."
    },
    dialogs: {
      deleteTitle: "Bist du sicher?",
      deleteText: "Wenn du dieses Angebot löschst, kann es von keinem mehr angesehen werden.",
      unlinkTitle: "Bist du sicher?",
      unlinkText: "Möchtest du dich von diesem Angebot abmelden?"
    },
    form: {
      detail: "Beschreibung",
      moreInfo: "Mehr Einzelheiten",
      when: "Wann",
      theseDays: "Alle Tage",
      next: "Das Nächste",
      where: "Wo",
      howMany: "Wie viele seid ihr?",
      freePlaces: "Offene Stellen",
      organizer: "Organisator",
      challenge: "Wenn dich dieses Angebot interessiert und du helfen möchtest, dann zögre nicht und bewirb dich!",
      typeTitle: "Dieses Angebot ist",
      protected: "gesichert",
      protectedTooltip: "Wenn du dich für ein gesichertes Angebot bewirbst, muss der Organisator deine Anfrage erst bestätigen."
    },
    join: {
      title: "Mach mit",
      text: "Um dich für das Angebot zu bewerben, musst du dich erst ",
      login: "Einloggen",
      requestSent: "Deine Anfrage wurde erfolgreich abgeschickt",
      successful: "Du hast dich erfolgreich eingeloggt",
      errors: {
        notExists: "Diese Angebot ist leider nicht mehr verfügbar",
        alreadyJoined: "Du machst hier bereits mit"
      }
    },
    cancelRequest: {
      title: "Storniere meine Anfrage",
      text: "Anfrage stornieren",
      full: "Voll",
      join: "Mach mit",
      successful: "Die Stornierung war erfolgreich"
    },
    edit: {
      title: "Bearbeite dein Angebot",
      successful: "Deine Änderungen wurden erfolgreich gespeichert",
      errors: {
        notExists: "Dieses Angebot ist leider nicht mehr verfügbar",
        notSpecified: "Dein Änderungsversuch ist leider fehlgeschlagen",
        notOwn: "Die Änderungen konnten nicht gespeichert werden, weil dieses Angebot nicht deins ist.",
        time: "Die Endzeit ist vor der Startzeit",
        invalidData: "Ungültige Informationen"
      }
    }
  },
  eventTable: {
    search: "Suche",
    createOffer: "Erstelle ein Angebot",
    showPastOffers: "Ehemalige Angebote",
    noOffers: "Es gibt leider keine Angebote"
  },
  linkedUsers: {
    title: "Helfer, die sich für dein Angebot gemeldet haben",
    chooseOffer: "Wähle ein Angebot",
    noUsers: "Es haben sich noch keine Helfer für dein Angebot gemeldet",
    all: "Alle",
    singleOffer: "Helfer, die sich für dein Angebot gemeldet haben"
  },
  requests: {
    title: "Helfer, die sich für dein gesichertes Angebot beworben haben",
    chooseOffer: "Wähle ein Angebot",
    noOffers: "Es gibt leider keine Angebote",
    offerFull: "Dieses Angebot ist bereits voll, deshalb kannst du keine weiteren Helfer bestätigen",
    freePlaces: "Anzahl der offenen Stellen",
    confirm: "Teilnahme bestätigen",
    decline: "Teilnahme ablehnen",
    skills: "Fähigkeiten",
    desription: "Beschreibung",
    send: "Senden",
    noRequests: "Es gibt leider keine neuen Anfragen für das Angebot"
  },
  eventCard: {
    offerFull: "Es haben sich bereits genügend Helfer gemeldet",
    freePlaces: "Es sind noch offene Stellen verfügbar",
    protectedTooltip: "Wenn du dich für ein gesichertes Angebot anmeldest, musst du auf die Bestätigung der Organisation warten."
  },
  eventForm: {
    basicInformation: "Informationen über das Angebot",
    offerTitle: "Name",
    offerDescription: "Beschreibung",
    offerType: "Art des Angebots",
    offerTags: "Fokus",
    offerFreePlaces: "Anzahl der offenen Stellen",
    offerPlace: "Ort",
    offerPlaceTooltip: "Wenn eine genaue Adresse vorhanden ist, gib diese bitte hier an. Falls es keine gibt, gib bitte die Stadt oder Region an.",
    offerAddress: "Adresse für die Veranstaltung",
    offerRegion: "Stadt/Region",
    offerTime: {
      title: "Zeit",
      subtitle: "Meine Angebote",
      oneDay: "1 Tag",
      oneDayDescription: "Diese Veranstaltung findet an einem bestimmten Tag statt.",
      multiDay: "Mehrere Tage",
      multiDayDescription: "Diese Veranstaltung findet am gleichen Ort über mehrere Tage statt.",
      repeating: "Wiederholend",
      repeatingDescription: "Diese Veranstaltung findet wiederholend statt (z.B. jeden Donnerstag).",
      longTerm: "Langfristig",
      longTermDescription: "Diese Veranstaltung findet über einen längeren Zeitraum statt, aber nicht an bestimmten Tagen.",
      date: "Datum",
      startDate: "Start",
      endDate: "Ende",
      chooseDays: "Wähle die Tage aus, an denen die Veranstaltung stattfindet",
      everyDay: "Jeden Tag",
      startTime: "Startzeit",
      endTime: "Voraussichtliche Endzeit",
      allDay: "Den ganzen Tag",
      protectedTooltip: "Wenn diese Veranstaltung als gesichert gilt, musst du jede Teilnahme bestätigen.",
      create: "Erstellen",
      change: "Änderungen speichern",
      submit: "Absenden"
    }
  },
  ozCard: {
    description: "Beschreibung",
    profile: "Profil"
  },
  inviteDialog: {
    invitation: "Einladung",
    invitationText: "Lade diesen Helfer zu einem den freien Angeboten ein.",
    notFound: "Es wurden leider keine Angebote gefunden.",
    cancel: "Abbrechen",
    confirm: "Bestätigen",
    error: "Es ist etwas schiefgelaufen, sodass du keine Helfer zu dieser Veranstaltung einladen kannst."
  },
  userCard: {
    description: "Beschreibung",
    profile: "Profil",
    invite: "Einladen",
    invitationError: "Die Einladung konnte nicht verschickt werden."
  },
  offer: {
    longTerm: "Langfristige Veranstaltung",
    allDay: "1-Tages-Veranstaltung",
    joined: "Beigetreten",
    protected: "Gesichertes Angebot",
    deleted: "Dieses Angebot wurde gelöscht."
  },
  filter: {
    hide: "Filter verbergen",
    show: "Filter anzeigen",
    clear: "Filter zurücksetzen",
    region: "Gib die Region an: ",
    regionLabel: "Region",
    type: "Art",
    title: "Suche nach dem Namen: ",
    titleLabel: "Name",
    date: "Wähle ein Datum aus: ",
    dateLabel: "Datum",
    dateText: "Suche nach einem bestimmten Tag:",
    longTerm: "Langfristige Angebote anzeigen",
    all: "Alle Angebote: ",
    nothingFound: "Es gibt leider keine Angebote."
  },
  deleteAccount: {
    message: "Dein Nutzerkonto wird nach drei Tagen gelöscht. Falls du es dich anders entscheidest, kannst du die Löschung binnen diesen drei Tagen mit dem Link in deiner Email wiederrufen.",
    errors: {
      checksumFailed: "Ungültiger Code. Bitte nutze den Link aus der Email.",
      invalidCode: "Dieser Code wurde bereits verwendet. Dein Konto wird in den nächsten Tagen gelöscht."
    }
  },
  deleteAccountCancel: {
    message: "Die Löschung deines Nutzerkontos wurde erfolgreich abgebrochen.",
    errors: {
      any: "Ungültiger oder bereits verwendeter Code. Um dein Nutzerkonto zu löschen, nutze bitte den Link aus der Email oder App."
    }
  },
  emailChange: {
    message: "Deine E-Mail-Adresse wurde erfolgreich geändert.",
    errors: {
      checksumFailed: "Ungültiger Code. Bitte nutze den Link aus der Email.",
      invalidCode: "Dieser Code wurde bereits verwendet. Die E-Mail-Adresse für dein Konto sollte geändert werden. Bei weiteren Problemen wende dich bitte an helfni@helfni.sk."
    }
  },
  emailChangeRevert: {
    message: "Die Änderung der E-Mail-Adresse wurde erfolgreich abgebrochen.",
    errors: {
      checksumFailed: "Ungültiger Code. Bitte nutze den Link aus der Email.",
      invalidCode: "Dieser Code wurde bereits verwendet. Die Änderung deine E-Mail-Adresse sollte rückgängig gemacht werden. Bei weiteren Problemen wende dich bitte an helfni@helfni.sk."
    }
  },
  mailSubscription: {
    cancelMessage: "Du hast deine E-Mail-Adresse erfolgreich abgemeldet."
  },
  dashboard: {
    created: "Das Angebot wurde erfolgreich erstellt",
    offers: "Deine Angebote"
  },
  appHome: {
    recommended: "Empfohlene Angebote: ",
    next: "Deine nächsten Angebote: ",
    none: "Du hast keine zukünftigen Teilnahmen.",
    search: "Suche nach Angeboten",
    awaits: "Auf Bestätigung wird gewartet: "
  },
  map: {
    filters: {
      hide: "Filter verbergen",
      show: "Filter anzeigen",
      clear: "Filter zurücksetzen"
    },
    region: {
      label: "Region",
      text: "In welcher Region suchst du?: "
    },
    tag: {
      label: "Fokus"
    },
    name: {
      label: "Name",
      text: "Suche nach dem Angebotsnamen: "
    },
    date: {
      label: "Datum",
      textCheckbox: "Suche nach einem bestimmten Tag",
      textFilter: "Wähle die Tage aus: "
    },
    longTerm: {
      label: "Langfristige Angebote anzeigen"
    },
    marker: {
      moreInfo: "Mehr Informationen",
      tag: {
        free: "Hilfe gesucht",
        linked: "Hilfe wird im Moment nicht benötigt",
        requested: "Teilnahme beantragen"
      }
    }
  },
  organisations: {
    filters: {
      hide: "Filter verbergen",
      show: "Filter anzeigen",
      clear: "Filter zurücksetzen"
    },
    region: {
      label: "Region",
      text: "In welcher Region suchst du?: "
    },
    nameOrDescription: {
      label: "Name oder Beschreibung",
      text: "Suche nach dem Namen der Organisation: "
    },
    notFound: {
      text: "Es wurde keine Organisation gefunden."
    }
  },
  profile: {
    title: "Mein Profil",
    type: {
      organization: "Organisation",
      volunteer: "Helfer"
    },
    delete: {
      label: "Bist du sicher?",
      text: "Nach Eingabe des Passworts erhälst du eine Email mit einem Link, mit dem du die Löschung deines Kontos bestätigen kannst. Nach 3 Tagen wird dann dein Konto, zusammen mit allen Angeboten, dauerhaft gelöscht. Wenn die Email nicht sofort ankommt, warte bitte ein paar Minuten und schau auch mal in deinem Spam-Ordner nach. Wenn die Email nach einiger Zeit immer noch nicht ankommt, wende dich bitte an helfni@helfni.sk",
    },
    changePassword: {
      label: "Passwort ändern",
      text: "Gib zuerst dein altes und dann dein neues Passwort ein.",
      oldPassword: "Altes Passwort",
      newPassword: "Neues Passwort",
      repeatPassword: "Passwort wiederholen",
      actions: {
        cancel: "Abbrechen",
        confirm: "Bestätigen"
      }
    },
    changeEmail: {
      label: "E-Mail-Adresse ändern",
      text: "Gib ein Passwort und eine neue E-Mail-Adresse ein. Wir senden dir dann einen Code zu, um die neue E-Mail-Adresse zu bestätigen. Wenn du diese Änderung rückgängig machen möchtest, ist dies innerhalb von 7 Tagen möglich, indem du auf den Link klickst, den du in der alten E-Mail erhalten hast.",
      password: "Passwort",
      newEmail: "Neue E-Mail-Adresse",
      actions: {
        cancel: "Abbrechen",
        confirm: "Bestätigen"
      }
    },
    info: {
      photo: "Foto hochladen",
      user: {
        label: "Helfer",
        name: "Name",
        region: "Region",
        username: "Nutzername",
        email: "E-Mail-Adresse"
      },
      organisation: {
        label: "Organisation",
        name: "Name der Organisation",
        username: "Benutzername",
        email: "E-Mail-Adresse",
        phone: "Telefonnummer",
        address: "Adresse"
      },
      description: "Beschreibung",
    },
    edit: {
      password: "Passwort ändern",
      email: "E-Mail-Adresse ändern",
      delete: "Konto löschen",
      editProfile: "Profil ändern",
      save: "Speichern",
      cancel: "Abbrechen"
    },
    unsubscribe: "Ich möchte keine Emails mit neuen Angeboten erhalten",
    messages: {
      notLoggedIn: "Du musst dich einloggen, um Helferprofile anschauen zu können.",
      notFound: "Das Profil konnte nicht gefunden werden",
      photoInvalidPicture: "Lade ein Profilfoto hoch",
      photoInvalidFile: "Lade eine gültige Datei hoch",
      photoFileTooBig: "Die Datei ist leider zu groß.",
      wrongPassword: "Ungültiges Passwort",
      passwordChanged: "Das Passwort wurde erfolgreich geändert",
      emailChangeSent: "Wir haben dir eine Email geschickt, um die Änderungen zu bestätigen.",
      subscriptionChanged: "Die Abo Einstellungen wurden geändert."
    }
  },
  users: {
    inviteSent: "Die Einladung wurde erfolgreich gesendet",
    filters: {
      hide: "Filter verbergen",
      show: "Filter anzeigen",
      clear: "Filter zurücksetzen"
    },
    region: {
      label: "Region",
      text: "In welcher Region suchst du?: "
    },
    tags: {
      label: "Fähigkeiten"
    },
    nameOrDescription: {
      label: "Name oder Beschreibung",
      text: "Suche nach dem Namen oder der Beschreibung: "
    },
    notFound: {
      text: "Es wurden keine Helfer gefunden."
    }
  },
  browserNotSupported: {
    label: "Es tut uns leid, aber dieser Browser wird leider nicht unterstützt.",
    text: "Bitte nutze einen neueren Browser oder unsere App."
  },
  warningDeleteAcc: {
    label: "Konto gelöscht",
    text: "Dieses Konto und alle damit verbundenen Informationen werden in den nächsten drei Tagen gelöscht. Du kannst diese Löschung rückgängig machen. Klick dazu auf ",
    link: "diesen Link."
  },
  routes: {
    landing: "Helfni",
    home: "Startseite",
    map: "Kartenansicht",
    users: "Helferliste",
    organisations: "Organisationsliste",
    eventsList: "Angebotsliste",
    eventCreate: "Ein Angebot erstellen",
    profile: "Profil",
    dashboard: "Startseite",
    login: "Login",
    register: "Registrierung",
    activateEmail: "Email aktivieren",
    recoverPassword: "Passwort wiederherstellen",
    deleteAccount: "Konto löschen",
    changeEmail: "E-Mail-Adresse ändern",
    logout: "Ausloggen",
    terms: "Nutzungsbedingungen",
    unsubscribe: "Abmelden",
    notSupported: "Nicht unterstützter Browser"
  },
  inputRules: {
    required: "Bitte fülle das aus",
    minLength6: "Min. 6 Zeichen",
    maxLength1000: "Max. 1000 Zeichen",
    maxLength100: "Max. 100 Zeichen",
    maxLength80: "Max. 80 Zeichen",
    maxLength64: "Max. 64 Zeichen",
    maxLength32: "Max. 32 Zeichen",
    usernameAt: "@ kann nicht verwendet werden",
    wrongEmail: "Ungültige E-Mail-Adresse",
    samePassword: "Die Passwörter stimmen nicht überein",
    wrongPhone: "Gib eine gültige Telefonnummer an",
    notNumber: "Gib eine Zahl an",
    notInteger: "Gib eine positive Zahl an",
    numberSize: "Bitte gib eine Zahl unter 10 000 000 an",
    dateFormat: "Bitte gib das Datum wie folgt an TT.MM.JJJJ",
    timeFormat: "Bitte gib die Zeit wie folgt an HH:MM",
    dateFuture: "Gib ein Datum in der Zukunft an",
    dateDifference: "Das Enddatum sollte nach dem Startdatum sein",
    timeFuture: "Gib eine Zeit in der Zukunft an",
    timeDifference: "Die Endzeit sollte vor der Startzeit sein",
    photoType: "Dieses Fotoformat wird nicht unterstützt",
    fileSize: "Max. 1 MB"
  },
  tags: {
    health: {
      name: "Gesundheitssektor",
      description: "Epidemiologen, öffentliche Gesundheitswesen, Gesundheitsstatistiker, Versorgungskette des Gesundheitswesens, Ärzte, Krankenschwestern, Labortechniker, Studenten der Medizin, Biologie und Chemie, Entsorgung medizinischer Abfälle."
    },
    management: {
      name: "Management",
      description: "Notfallmanagement und -koordination, Notfallkommunikation, Marketing und Medien, Informationsmanagement, Datenanalyse, Überwachung, Auswertung und Berichterstattung."
    },
    food: {
      name: "Essen",
      description: ""
    },
    distribution: {
      name: "Logistik",
      description: "Transport, Lieferung und Verladung von Waren."
    },
    education: {
      name: "Bildung",
      description: "Pädagogik, Kinderschutz, Beratung."
    },
    children: {
      name: "Kinder",
      description: ""
    },
    seniors: {
      name: "Senioren",
      description: ""
    },
    casework: {
      name: "Psychosoziale Hilfe",
      description: "Psychosoziale Unterstützung und Beratung und Sozialarbeit."
    },
    socialHelp: {
      name: "Soziale Hilfe",
      description: "Sozioökonomische und Gesundheitsdienste, Anthropologie, Soziologie."
    },
    sewing: {
      name: "Nähen",
      description: "Nähen von medizinischen Masken, Schutzanzügen und anderem Hilfsmaterial."
    },
    communityWork: {
      name: "Gemeinschaftsarbeit",
      description: "Gastronomie, Beschaffung, Lebensunterhalt und Stadtpflege."
    }
  },
  eventPresets: {
    psychosocialHelp: {
      name: "Psychosoziale Hilfe"
    },
    teachingChildren: {
      name: "Kinder online unterrichten (Lehrer, Kulturbeauftragte, Schauspieler)"
    },
    telephony: {
      name: "Telefonie (Verwaltung und Organisation)"
    },
    disinfection: {
      name: "Desinfektion (Erfahrung mit Sprays und Chemikalien)"
    },
    sewingDrapes: {
      name: "Medizinische Masken nähen"
    },
    mobileLaboratory: {
      name: "Mobile Labore (Medizinstudenten)"
    },
    collectionOfMedicines: {
      name: "Abholung von Medikamenten in der Apotheke"
    },
    shoppingForSeniors: {
      name: "Einkaufen für Senioren, Alleinerziehende oder Personen in Quarantäne"
    },
    distribution: {
      name: "Lieferung (Lebensmittel, Lebensmittel, Medikamente usw.) für ältere oder anderweitig benachteiligte Personen"
    },
    medicalDistribution: {
      name: "Verteilung von medizinischem und Schutzmaterial"
    },
    writingOrders: {
      name: "Schreiben von Bestellungen zum Kauf von lebensnotwendigen Lebensmitteln"
    },
    foodDelivery: {
      name: "Lieferung von Speisen und Bestellungen"
    },
    assistanceDelivery: {
      name: "Lieferung von materieller Hilfe"
    },
    other: {
      name: "Etwas anderes?"
    }
  }
}
