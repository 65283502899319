export default {
  language: "Język",
  cancel: "Anulować",
  popup: {
    password: "Hasło",
    cancel: "Anulować",
    confirm: "Potwierdzić"
  },
  data: {
    myRegion: "Obszar",
    allRegions: "Wszystkie okolice",
    noData: "Brak danych",
  },
  errors: {
    errorTitle: "Błąd",
    back: "Powrót na główną stronę",
    form: "Formularz wypełniony nieprawidłowo",
    address: "Błędny adres",
    atLeastOneDay: "Proszę wybrać przynajmniej jeden dzień",
    errorText: "Przepraszamy, wystąpił błąd z naszej strony i nie byliśmy w stanie obsłużyć tego żądania. Spróbuj ponownie później. Jeśli błąd będzie się powtarzał, skontaktuj się z nami pod adresem helfni@helfni.sk."
  },
  activeEmail: {
    text: "E-mail zweryfikowany, masz dostęp",
    login: "wejdź",
    invalidCode: "Nieprawidłowy kod. Użyj linku w e-mailu, aby aktywować konto.",
    alreadyConfirmed: "Twój adres e-mail został już zweryfikowany, możesz się zalogować."
  },
  login: {
    confirm: "Potwierdź swój adres e-mail za pomocą linku w wiadomości, którą wysłaliśmy na Twój adres. Po potwierdzeniu będziesz mógł się zalogować. Jeśli e-mail nie dotrze wkrótce, poczekaj kilka minut i sprawdź folder ze spamem. Jeśli nie otrzymałeś jeszcze wiadomości, skontaktuj się z nami pod adresem helfni@helfni.sk.",
    register: "Aby wyświetlić listę wolontariuszy, musisz się najpierw zarejestrować i zalogować się jako organizacja.",
    welcome: "Witamy!",
    username: "wprowadź imię lub adres e-mail",
    password: "Hasło",
    forgot: "Nie pamiętam hasła",
    login: "Wejdź",
    noAccount: "Nie masz konta?",
    createAccount: "Utwórz nowe",
    incorrectPassword: "Nieprawidłowe hasło",
    userNotFound: "Nieprawidłowy login lub adres e-mail",
    emailNotVerified: "Dla zalogowania potwierdź swój adres e-mail za pomocą linku w wiadomości, który wysłaliśmy na Twój adres"
  },
  passwordRecovery: {
    recover: "Odzyskać hasło",
    enterNewPassword: "Wpisz nowe hasło",
    newPassword: "Nowe hasło",
    changePassword: "Zmień hasło",
    codeUsed: "Ten kod do resetowania hasła został już użyty.",
    invalidCode: "Nieprawidłowy kod",
    inputEmail: "Wpisz swój adres e-mail poniżej. Jeśli jest zgodny z adresem powiązanym z Twoim kontem Helfni, wyślemy Ci link do resetowania hasła.",
    email: "Email",
    messageSent: "Jeśli wprowadzony adres e-mail jest poprawny, otrzymasz link do zresetowania hasła. Jeśli e-mail nie dotrze wkrótce, poczekaj kilka minut i sprawdź folder ze spamem. Jeśli nie otrzymałeś jeszcze wiadomości, skontaktuj się z nami pod adresem helfni@helfni.sk.",
    sendEmail: "Wyślij email do aktualizacji",
    success: "Hasło zostało pomyślnie zmienione. Masz dostęp",
    login: "wejdź"
  },
  register: {
    title: "Zarejestruj się w HELFNI i pomagaj!",
    accountType: {
      title: "Wybierz jeden z dwóch rodzajów profilu",
      volunteer: "Wolontariusz",
      volunteerDescription: "Za pomocą tego konta znajdziesz oferty organizacji, którym chcesz pomóc.",
      organisation: "Organizacja",
      organisationDescription: "Za pomocą tego konta możesz tworzyć oferty dla osób, które chcą pomóc."
    },
    continue: "Dalej",
    registration: "Rejestracja",
    back: "Powrót",
    personalInformation: {
      title: "Informacja osobista",
      name: "Imię i nazwisko",
      email: "Email",
      region: "Okolice",
      descriptionText: "Napisz w tej sekcji coś o sobie. Czy masz już jakieś doświadczenie w wolontariacie, jaka jest Twoja motywacja i wszystko, co uważasz za ważne dla siebie. Ten opis jest postrzegany przez organizacji, które aktywnie poszukują wolontariuszy. Dlatego informacje, które wprowadzisz, mogą zwiększyć Twoje szanse, że organizacja wybierze Ciebie.",
      descriptionLabel: "Napisz coś o sobie. W szczególności – wcześniejsze doświadczenie.",
      skills: "Moje umiejętności, wiedza i zdolności"
    },
    organisation: {
      title: "Informacja o organizacji",
      name: "Nazwa organizacji",
      address: "Dokładny adres",
      region: "Dzielnica",
      description: "Opis organizacji",
      contactTitle: "Kontakty",
      phone: "Numer telefonu",
      email: "Email"
    },
    loginDetails: {
      title: "Wybierz opcje logowania",
      username: "Imię użytkownika",
      password: "Hasło",
      repeatPassword: "Powtórz hasło"
    },
    consent: "Zgadzam się",
    terms: "na warunki użytkowania",
    register: "Zarejestrować się",
    errors: {
      usernameTaken: "Użytkownik z tym loginem już istnieje",
      emailTaken: "Użytkownik z tym adresem e-mail już istnieje",
      fixErrors: "Popraw poniższe błędy"
    }
  },
  navbar: {
    dashboard: "Podgląd",
    home: "Na główną",
    offers: "Oferty",
    map: "Mapa",
    volunteers: "Wolontariusze",
    organisations: "Organizacji",
    logout: "Wyloguj się",
    login: "Zaloguj się",
    register: "Zarejestruj się",
    profile: "Profil"
  },
  landing: {
    title: "swojemu miastu",
    subtitle: "potrzebuje tego teraz bardziej niż kiedykolwiek.",
    wantToHelp: "Chcesz pomóc?",
    seeOffers: "Zobacz oferty",
    howToHelp: {
      title: "Jak pomóc razem z HELFNI?",
      register: "Zarejestruj się tu lub w aplikacji",
      choose: "Wybierz oferty, w których chcesz pomóc, lub utwórz własną ofertę",
      help: "Zostaw ślad",
    },
    iWantToRegister: "Chcę się zarejestrować",
    whoWeAre: {
      title: "Kim jesteśmy?",
      text: "{kajo} zajmuję się utrzymaniem operacyjnym i aktywnym rozwojem HELFNI. Oryginalna idea HELFNI pochodzi od grupy studentów z miasta Košice (Słowacja) wspieranych przez Instytut Rozwoju Społeczeństwa i Green Foundation."
    },
    whatWeDo: {
      title: "Czym się zajmujemy?",
      text: "{helfni} to platforma łącząca wolontariuszy z działaniami wolontariackimi, utworzona w celu informowania o inicjatywach udzielenia pomocy, jak również pośrednictwa w skutecznej komunikacji między wolontariuszami, organizacjami publicznymi i prywatnymi. W tych czasach bardziej niż kiedykolwiek pomoc jest niezwykle cenna."
    },
    numbers: {
      title: "Jesteśmy RAZEM",
      volunteers: "wolontariuszy",
      organisations: "organizacji",
      offers: "ofert pomocy"
    },
    offerList: {
      title: "Helfni dla nas",
      notFound: "Nie znaleziono żadnej oferty",
      showMore: "pokazać więcej"
    },
    map: {
      title: "Gdzie możesz nas znaleźć?",
      showOnMap: "pokaż na mapie"
    },
    motto: "HELFNI - Pomagamy pomagać",
    partners: "Współpracujemy z tymi organizacjami",
    footer: {
      socialMedia: "Znajdzie nas w mediach społecznościowych",
      disclaimer: "Informacje dostarczane przez ten program nie są cenzurowane. Każdy zarejestrowany użytkownik ponosi pełną odpowiedzialność za treść, prawdziwość i aktualność informacji zawartych w profilu."
    }
  },
  event: {
    back: "Cofnij",
    create: "Utwórz ofertę",
    delete: "Kasuj ofertę",
    cancel: "Skasować",
    gallery: "Galeria wydarzenia",
    map: "Mapa",
    errors: {
      endBeforeStart: "Czas zakończenia jest przed czasem rozpoczęcia",
      invalidAddress: "Nie udało się rozpoznać adresu. Wpisz go w innym formacie lub wprowadź inny adres."
    },
    dialogs: {
      deleteTitle: "Jesteś pewien?",
      deleteText: "Usunięcie oferty z naszego systemu spowoduje to, że i nikt nie będzie mógł na nią trafić ani na nią odpowiedzieć.",
      unlinkTitle: "Jesteś pewien?",
      unlinkText: "Czy jesteś pewien, że chcesz skasować subskrypcję tej oferty?"
    },
    form: {
      detail: "Szczegóły",
      moreInfo: "Więcej informacji",
      when: "Kiedy",
      theseDays: "W trakcie",
      next: "Najbliższe",
      where: "Gdzie",
      howMany: "Ile osób",
      freePlaces: "Wolnych miejsc",
      organizer: "Organizator",
      challenge: "Jeśli zainteresowała Ciebie ta oferta i chcesz pomóc, nie wahaj się, aplikuj i nie zapomnij poinformować organizację, że zamierzasz pomóc.",
      typeTitle: "Ta oferta jest",
      protected: "zabezpieczona",
      protectedTooltip: "Jeśli potrzebujesz dostępu do zabezpieczonej oferty, musisz poczekać, aż organizator potwierdzi Twoje zgłoszenie."
    },
    join: {
      title: "Dołączyć",
      text: "Zeby dołączyć do oferty konieczne jest ",
      login: "zalogowanie",
      requestSent: "Prośba o dołączenie wysłana pomyślnie",
      successful: "Pomyślne logowanie",
      errors: {
        notExists: "Ta oferta jest już nieaktualna",
        alreadyJoined: "Dołączyłeś już do tej oferty"
      }
    },
    cancelRequest: {
      title: "Anulować prośbę",
      text: "Usunąć prośbę",
      full: "Wypełnione",
      join: "Dołączyć",
      successful: "Wylogowanie powiodło się"
    },
    edit: {
      title: "Edytuj ofertę",
      successful: "Oferta zmieniona pomyślnie",
      errors: {
        notExists: "Ta oferta jest już nieaktualna",
        notSpecified: "Nie udało się zmienić danych",
        notOwn: "Danych nie można zmienić, ponieważ nie jesteś właścicielem tej oferty",
        time: "Czas zakończenia jest przed czasem rozpoczęcia",
        invalidData: "Formularz zawiera nieprawidłowe dane"
      }
    }
  },
  eventTable: {
    search: "Szukaj",
    createOffer: "Utwórz ofertę",
    showPastOffers: "Wyświetl wcześniejsze oferty",
    noOffers: "Brak ofert"
  },
  linkedUsers: {
    title: "Wolontariusze, którzy zapisali się na Twoi oferty",
    chooseOffer: "Wybierz ofertę",
    noUsers: "Żaden wolontariusz nie zapisał się jeszcze do tej oferty",
    all: "Wszystkie",
    singleOffer: "Wolontariusze, którzy zapisali się do tej oferty"
  },
  requests: {
    title: "Wolontariusze, którzy zgłosili się do Twoich zabezpieczonych oferty",
    chooseOffer: "Wybierz ofertę",
    noOffers: "Brak ofert",
    offerFull: "Ta oferta jest już wypełniona, nie możesz potwierdzić nowych zgłoszeń",
    freePlaces: "Liczba wakatów",
    confirm: "Potwierdzić udział",
    decline: "Odmówić udziału",
    skills: "Umiejętności",
    desription: "Opis",
    send: "Wysłać",
    noRequests: "Obecnie nie ma nowych zgłoszeń do wybranej oferty"
  },
  eventCard: {
    offerFull: "Nie ma wolnych miejsc w tej ofercie, nie możesz się do niej zapisać",
    freePlaces: "W tej ofercie są jeszcze wolne miejsca",
    protectedTooltip: "Jeśli prosisz o dołączenie do zabezpieczonej oferty, musisz poczekać, aż organizator potwierdzi Twoją prośbę."
  },
  eventForm: {
    basicInformation: "Podstawowe informacje",
    offerTitle: "Nowe oferty",
    offerDescription: "Opis oferty",
    offerType: "Rodzaj oferty",
    offerTags: "Tagi ofert",
    offerFreePlaces: "Liczba wakatów",
    offerPlace: "Miejsce oferty",
    offerPlaceTooltip: "Jeśli ta oferta zawiera dokładny adres, pod którym będzie się odbywać, wypełnij go, a w większości przypadków dzielnica zostanie przypisana automatycznie. Jeśli nie ma dokładnego adresu, zaznacz przynajmniej obszar.",
    offerAddress: "Adres wydarzenia",
    offerRegion: "Dzielnica",
    offerTime: {
      title: "Czas oferty",
      subtitle: "Moja oferta jest",
      oneDay: "Jeden dzień",
      oneDayDescription: "Oferta jednodniowa to oferta ważna tylko w określonym dniu.",
      multiDay: "Kilkudniowa",
      multiDayDescription: "Oferta kilkudniowa jest ważna jednorazowo, ale trwa kilka dni.",
      repeating: "Powtarzająca się",
      repeatingDescription: "Oferta regularna, która obowiązuje w regularnych odstępach czasu. Na przykład, co czwartek.",
      longTerm: "Długoterminowa",
      longTermDescription: "Oferta długoterminowa nie ma określonego czasu realizacji, ale wymaga więcej czasu.",
      date: "Data wydarzenia",
      startDate: "Początek",
      endDate: "Koniec",
      chooseDays: "Wybierz dni, w których odbywa się wydarzenie",
      everyDay: "Codziennie",
      startTime: "Zaczyna się o",
      endTime: "Orientowany czas zakończenia",
      allDay: "Wydarzenie trwa cały dzień",
      protectedTooltip: "Jeśli oferta jest zabezpieczona, będziesz musiał potwierdzić prośbę każdego wolontariusza o udział w tym wydarzeniu.",
      create: "Utworzyć",
      change: "Zapisz zmiany",
      submit: "Wysłać"
    }
  },
  ozCard: {
    description: "Opis",
    profile: "Profil"
  },
  inviteDialog: {
    invitation: "Zaproszenie",
    invitationText: "Zaproś tego wolontariusza do jednej ze swoich bezpłatnych ofert",
    notFound: "Nie znaleziono ofert",
    cancel: "Anulować",
    confirm: "Potwierdzić",
    error: "Wystąpił błąd, nie można zaprosić wolontariuszy."
  },
  userCard: {
    description: "Opis",
    profile: "Profil",
    invite: "Zaproś",
    invitationError: "Nie udało się wysłać zaproszenia."
  },
  offer: {
    longTerm: "Oferta długoterminowa",
    allDay: "Cały dzień",
    joined: "Ci, którzy dołączyli",
    protected: "Oferta zabezpieczona",
    deleted: "Ta oferta została usunięta."
  },
  filter: {
    hide: "Ukryj filtry",
    show: "Pokaż filtry",
    clear: "Wyczyść filtry",
    region: "Wybierz obszar, w którym chcesz otrzymywać oferty: ",
    regionLabel: "Obszar",
    type: "Tagi ofert",
    title: "Wyszukaj ofertę po nazwie: ",
    titleLabel: "Nazwa oferty",
    date: "Wybierz dni, w których potrzebujesz ofert: ",
    dateLabel: "Data wydarzenia",
    dateText: "Szukaj w określone dni",
    longTerm: "Pokaż oferty długoterminowe",
    all: "Wszystkie oferty: ",
    nothingFound: "Nie znaleziono ofert"
  },
  deleteAccount: {
    message: "Twoje konto zostanie automatycznie usunięte, jeśli nie anulujesz usunięcia w ciągu trzech dni, klikając link, który znajdziesz w e-mailu.",
    errors: {
      checksumFailed: "Nieprawidłowy kod. Użyj linku w e-mailu, aby usunąć swoje konto.",
      invalidCode: "Ten kod został już wykorzystany, konto jest gotowe do usunięcia, jeśli nie anulujesz usunięcia."
    }
  },
  deleteAccountCancel: {
    message: "Usunięcie konta zostało pomyślnie anulowane.",
    errors: {
      any: "Nieprawidłowy lub już użyty kod. Wznów usuwanie konta, korzystając z linku w e-mailu lub linku w aplikacji."
    }
  },
  emailChange: {
    message: "Adres e-mail Twojego konta został pomyślnie zmieniony.",
    errors: {
      checksumFailed: "Nieprawidłowy kod. Użyj linku w e-mailu, aby potwierdzić zmianę adresu e-mail.",
      invalidCode: "Ten kod został już użyty, i adres e-mail Twojego konta powinien już zostać zmieniony. W przeciwnym razie skontaktuj się z nami pod adresem helfni@helfni.sk."
    }
  },
  emailChangeRevert: {
    message: "Zmiana adresu e-mail została pomyślnie anulowana.",
    errors: {
      checksumFailed: "Nieprawidłowy kod. Użyj linku w e-mailu, aby anulować zmiany.",
      invalidCode: "Ten kod został już wykorzystany, a zmiana adresu e-mail na Twoim koncie powinna już zostać anulowana. W przeciwnym razie skontaktuj się z nami pod adresem helfni@helfni.sk."
    }
  },
  mailSubscription: {
    cancelMessage: "Pomyślnie anulowano subskrypcję powiadomień e-mail."
  },
  dashboard: {
    created: "Oferta została utworzona pomyślnie",
    offers: "Twoje oferty"
  },
  appHome: {
    recommended: "Polecane oferty: ",
    next: "Moje kolejne oferty: ",
    none: "Nie dołączyłeś jeszcze do żadnej oferty.",
    search: "Szukaj ofert",
    awaits: "Oczekują na zatwierdzenie: "
  },
  map: {
    filters: {
      hide: "Ukryj filtry",
      show: "Pokaż filtry",
      clear: "Wyczyść filtry",
    },
    region: {
      label: "Obszar",
      text: "Wybierz obszar, w którym chcesz otrzymywać oferty: "
    },
    tag: {
      label: "Tagi ofert"
    },
    name: {
      label: "Nazwa oferty",
      text: "Wyszukaj ofertę według nazwy: "
    },
    date: {
      label: "Data wydarzenia",
      textCheckbox: "Szukaj w określone dni",
      textFilter: "Wybierz dni, w które potrzebujesz ofert: "
    },
    longTerm: {
      label: "Pokaż oferty długoterminowe"
    },
    marker: {
      moreInfo: "Kliknij aby dowiedzieć się więcej",
      tag: {
        free: "Potrzebuje wolontariuszy",
        linked: "Nie potrzeba wolontariuszy",
        requested: "Poproszono o dołączenie"
      }
    }
  },
  organisations: {
    filters: {
      hide: "Ukryj filtry",
      show: "Pokaż filtry",
      clear: "Wyczyść filtry",
    },
    region: {
      label: "Obszar",
      text: "Wybierz obszar, w którym szukasz organizacje: "
    },
    nameOrDescription: {
      label: "Nazwa lub opis",
      text: "Wyszukaj organizacje według nazwy lub opisu: "
    },
    notFound: {
      text: "Nie znaleziono organizacji"
    }
  },
  profile: {
    title: "Mój profil",
    type: {
      organization: "Organizacja",
      volunteer: "Wolontariusz"
    },
    delete: {
      label: "Jesteś pewny?",
      text: "Po wprowadzeniu hasła wyślemy Ci wiadomość e-mail z linkiem dla potwierdzenia usunięcia konta. W ciągu 3 dni od potwierdzenia Twoje konto zostanie usunięte na zawsze wraz ze wszystkimi Twoimi ofertami. Jeśli e-mail nie dotrze natychmiast, poczekaj kilka minut i sprawdź folder ze spamem. Jeśli nie otrzymałeś jeszcze wiadomości e-mail, skontaktuj się z nami pod adresem helfni@helfni.sk.",
    },
    changePassword: {
      label: "Zmiana hasła",
      text: "Wpisz swoje stare hasło, a następnie podaj nowe hasło.",
      oldPassword: "Stare hasło",
      newPassword: "Nowe hasło",
      repeatPassword: "Powtórz nowe hasło",
      actions: {
        cancel: "Anulować",
        confirm: "Potwierdzić"
      }
    },
    changeEmail: {
      label: "Zmiana adresu e-mail",
      text: "Wpisz swoje aktualne hasło i nowy adres e-mail. Wyślemy Ci kod, aby potwierdzić nowy adres i dokończyć zmianę. Jeśli chcesz cofnąć tę zmianę, możesz to zrobić w ciągu 7 dni, klikając w link który otrzymasz na stary adres e-mail.",
      password: "Hasło",
      newEmail: "Nowy adres e-mail",
      actions: {
        cancel: "Anulować",
        confirm: "Potwierdzić"
      }
    },
    info: {
      photo: "Dodaj zdjęcie",
      user: {
        label: "Wolontariusz",
        name: "Imję",
        region: "Obszar",
        username: "Imię użytkownika",
        email: "Email"
      },
      organisation: {
        label: "Organizacja",
        name: "Nazwa organizacji",
        username: "Imię użytkownika",
        email: "Email",
        phone: "Telefon",
        address: "Adres"
      },
      description: "Opis",
    },
    edit: {
      password: "Zmienić hasło",
      email: "Zmienić adres e-mail",
      delete: "Usunąć konto",
      editProfile: "Edytować profil",
      save: "Zapisz zmiany",
      cancel: "Anuliować"
    },
    unsubscribe: "Nie chcę otrzymywać e-maili z nowymi ofertami",
    messages: {
      notLoggedIn: "Musisz być zalogowany, aby przeglądać profile wolontariuszy.",
      notFound: "Nie znaleziono profilu",
      photoInvalidPicture: "Dodaj aktualne zdjęcie jako zdjęcie profilowe",
      photoInvalidFile: "Dodaj plik",
      photoFileTooBig: "Zdjęcie profilowe jest za duże, użyj mniejszego pliku.",
      wrongPassword: "Nieprawidłowe hasło",
      passwordChanged: "Hasło zostało pomyślnie zmienione",
      emailChangeSent: "Wysłano wiadomość e-mail w celu potwierdzenia zmiany",
      subscriptionChanged: "Ustawienia poczty e-mail zmieniono pomyślnie"
    }
  },
  users: {
    inviteSent: "Zaproszenie wysłane pomyślnie",
    filters: {
      hide: "Ukryj filtry",
      show: "Pokaż filtry",
      clear: "Wyczyść filtry",
    },
    region: {
      label: "Obszar",
      text: "Wybierz obszar, w którym poszukujesz wolontariuszy: "
    },
    tags: {
      label: "Umiejętności wolontariuszy"
    },
    nameOrDescription: {
      label: "Nazwa lub opis",
      text: "Szukaj wolontariuszy według nazwy lub opisu: "
    },
    notFound: {
      text: "Nie znaleziono wolontariuszy"
    }
  },
  browserNotSupported: {
    label: "Przepraszamy, ta przeglądarka nie jest obsługiwana.",
    text: "Użyj jednej z nowszych przeglądarek lub naszej aplikacji mobilnej."
  },
  warningDeleteAcc: {
    label: "USUNIĘTE KONTO",
    text: " to konto i wszystkie powiązane dane zostaną usunięte w ciągu trzech dni. Usunięcie można anulować ",
    link: "pod tym linkiem."
  },
  routes: {
    landing: "Helfni",
    home: "Główna",
    map: "Oferty na mapie",
    users: "Lista wolontariuszy",
    organisations: "Lista organizacji",
    eventsList: "Lista ofert",
    eventCreate: "Utwórz propozycję",
    profile: "Profil",
    dashboard: "Pogląd",
    login: "Logowanie",
    register: "Rejestracja",
    activateEmail: "Aktywacja adresu e-mail",
    recoverPassword: "Odzyskiwanie hasła",
    deleteAccount: "Usunięcie konta",
    changeEmail: "Zmiana adresu e-mail",
    logout: "Wylogowanie",
    terms: "Warunki korzystania",
    unsubscribe: "Ustawienia poczty e-mail",
    notSupported: "Twoja przeglądarka nie jest obsługiwana"
  },
  inputRules: {
    required: "Pole jest wymagane",
    minLength6: "Minimalna długość to 6 znaków",
    maxLength1000: "Maksymalna długość to 1000 znaków",
    maxLength100: "Maksymalna długość to 100 znaków",
    maxLength80: "Maksymalna długość to 80 znaków",
    maxLength64: "Maksymalna długość to 64 znaka",
    maxLength32: "Maksymalna długość to 32 znaka",
    usernameAt: "Nazwa użytkownika nie może zawierać @",
    wrongEmail: "Niepoprawny adres e-mail",
    samePassword: "Hasła się nie zgadzają",
    wrongPhone: "Wpisz aktualny numer telefonu",
    notNumber: "Wpisz liczbę",
    notInteger: "Wpisz wartość większą od zera",
    numberSize: "Wpisz liczbę mniejszą niż 10000000",
    dateFormat: "Wprowadź prawidłową datę w formacie DD.MM.RRRR",
    timeFormat: "Wprowadź aktualny czas w formacie GG:MM",
    dateFuture: "Podaj wolną datę w przyszłości",
    dateDifference: "Data zakończenia musi być późniejsza niż data rozpoczęcia",
    timeFuture: "Wpisz czas w przyszłości",
    timeDifference: "Czas zakończenia musi być późniejszy niż czas rozpoczęcia",
    photoType: "Nieobsługiwany format obrazka",
    fileSize: "Maksymalny rozmiar pliku to 1 MB"
  },
  tags: {
    health: {
      name: "Opieka zdrowotna",
      description: "Epidemiolodzy, zdrowie publiczne, statystyka zdrowia, łańcuch dostaw w sektorze opieki zdrowotnej, lekarze, pielęgniarki, technicy laboratoryjni, studenci medycyny, biologii i chemii, gospodarka odpadami medycznymi.", 
    },
    management: {
      name: "Zarządzanie",
      description: "Zarządzanie i koordynacja kryzysowa, wczesne ostrzeganie, marketing i media, zarządzanie informacją, analiza danych, monitorowanie, ocena i raportowanie."
    },
    food: {
      name: "Jedzenie",
      description: ""
    },
    distribution: {
      name: "Dystrybucja",
      description: "Transport, dostawa i załadunek towarów."
    },
    education: {
      name: "Edukacja",
      description: "Pedagogika, ochrona dzieci, doradztwo."
    },
    children: {
      name: "Dzieci",
      description: ""
    },
    seniors: {
      name: "Seniorzy",
      description: ""
    },
    casework: {
      name: "Opieka społeczna",
      description: "Wsparcie i poradnictwo psychologiczne i społeczne oraz praca socjalna."
    },
    socialHelp: {
      name: "Pomoc społeczna",
      description: "Usługi społeczno-ekonomiczne i zdrowotne, antropologia, socjologia."
    },
    sewing: {
      name: "Szycie",
      description: "Szycie masek medycznych, kombinezonów ochronnych i innych materiałów pomocniczych."
    },
    communityWork: {
      name: "Praca społecznośćiowa",
      description: "Gastronomia, zaopatrzenie, źródła utrzymania i opieka miejska."
    }
  },
  eventPresets: {
    psychosocialHelp: {
      name: "Pomoc psychologiczna i społeczna"
    },
    teachingChildren: {
      name: "Nauczanie dzieci online (nauczyciele, animatorzy kultury, aktorzy)"
    },
    telephony: {
      name: "Telefonia (administracja i organizacja)"
    },
    disinfection: {
      name: "Dezynfekcja (doświadczenie z aerozolami i chemikaliami)"
    },
    sewingDrapes: {
      name: "Szycie masek medycznych"
    },
    mobileLaboratory: {
      name: "Laboratoria mobilne (studenci medycyny)"
    },
    collectionOfMedicines: {
      name: "Odbiór leków w aptece"
    },
    shoppingForSeniors: {
      name: "Zakupy dla seniorów, samotnych rodziców lub osób poddanych kwarantannie"
    },
    distribution: {
      name: "Dostawa (jedzenie, artykuły spożywcze, lekarstwa itp.) dla osób starszych lub innych osób w niekorzystnej sytuacji"
    },
    medicalDistribution: {
      name: "Dystrybucja materiałów medycznych i ochronnych"
    },
    writingOrders: {
      name: "Pisanie zamówień na zakup niezbędnych produktów spożywczych"
    },
    foodDelivery: {
      name: "Dostawa jedzenia i zamówień"
    },
    assistanceDelivery: {
      name: "Dostawa i pomoc materialna"
    },
    other: {
      name: "Inne"
    }
  }
}
