export default {
  language: "Language",
  cancel: "Cancel",
  popup: {
    password: "Password",
    cancel: "Cancel",
    confirm: "Confirm"
  },
  data: {
    myRegion: "My region",
    allRegions: "All regions",
    noData: "No data available",
  },
  errors: {
    errorTitle: "Error",
    back: "Back to homepage",
    form: "Incorrectly filled form",
    address: "Invalid address",
    atLeastOneDay: "Please choose at least one day",
    errorText: "We apologize, some error occurred and we were unable to process this request. Please try again later. If the error persists, please contact us at helfni@helfni.sk."
  },
  activeEmail: {
    text: "Email address confirmed successfully. You can now",
    login: "LOG IN",
    invalidCode: "Invalid code. To activate your account use the link provided in received email.",
    alreadyConfirmed: "Your email has already been confirmed, you can log in."
  },
  login: {
    confirm: "Confirm your email address using the link in the email we sent to your email address. After confirmation, you will be able to log in. If the email does not arrive immediately, please wait a few minutes and check the spam folder also. If you still haven't received the email, please contact us by email at helfni@helfni.sk.",
    register: "In order to view the volunteers, you must first register and log in as an organization.",
    welcome: "Welcome!",
    username: "Login name or email",
    password: "Password",
    forgot: "I forgot my password",
    login: "Log in",
    noAccount: "Don't have an account?",
    createAccount: "Create new one",
    incorrectPassword: "Invalid password",
    userNotFound: "Incorrect login name or email",
    emailNotVerified: "Confirm the email using the link we sent to your email address so you can log in"
  },
  passwordRecovery: {
    recover: "Password recovery",
    enterNewPassword: "Enter a new password",
    newPassword: "New password",
    changePassword: "Change password",
    codeUsed: "This password reset code has already been used.",
    invalidCode: "Invalid code",
    inputEmail: "Enter your email below and if it matches the email associated with your account, we'll send you a link to your password.",
    email: "Email",
    messageSent: "If the email you entered was correct, you should receive a password reset link. If the email does not arrive immediately, please wait a few minutes and check the spam folder also. If you still haven't received the email, please contact us by email at helfni@helfni.sk.",
    sendEmail: "Send recovery email",
    success: "Password has been changed successfully. You can",
    login: "LOG IN"
  },
  register: {
    title: "Register at HELFNI and help!",
    accountType: {
      title: "Choose from two types of account",
      volunteer: "Volunteer",
      volunteerDescription: "With this account, you will be able to select offers from the organizations you want to help.",
      organisation: "Organization",
      organisationDescription: "With this account, you will be able to create offers that people willing to help can apply."
    },
    continue: "Continue",
    registration: "Registration",
    back: "Back",
    personalInformation: {
      title: "Personal details",
      name: "Name and surname",
      email: "Email",
      region: "My region",
      descriptionText: "Write something about yourself in this section. Whether your previous experience of volunteering, your motivation to venture your city, or whatever you consider important. This description might be seen by organizations that can actively seek for volunteers and can therefore increase your chances to be choosen by the organizations.",
      descriptionLabel: "A description of yourself or references to previous experience",
      skills: "My skills, knowledge and abilities"
    },
    organisation: {
      title: "Organization details",
      name: "Name of the organization",
      address: "Exact address",
      region: "Region",
      description: "Description of the organization",
      contactTitle: "Contact details",
      phone: "Phone number",
      email: "Email"
    },
    loginDetails: {
      title: "Login data",
      username: "Username",
      password: "Password",
      repeatPassword: "Repeat password"
    },
    consent: "I agree with",
    terms: "terms of use",
    register: "Register",
    errors: {
      usernameTaken: "A user with this login already exists",
      emailTaken: "A user with this email already exists",
      fixErrors: "Please correct all errors below"
    }
  },
  navbar: {
    dashboard: "Home",
    home: "Home",
    offers: "Offers",
    map: "Map",
    volunteers: "Volunteers",
    organisations: "Organizations",
    logout: "Log out",
    login: "Log in",
    register: "Registration",
    profile: "Profile"
  },
  landing: {
    title: "your city",
    subtitle: "now it needs even more than ever.",
    wantToHelp: "Do you want to help?",
    seeOffers: "See offers",
    howToHelp: {
      title: "How to help with HELFNI?",
      register: "Register on the web or in the app",
      choose: "Choose the offers you want to help with or create these offers",
      help: "Leave a footprint around you",
    },
    iWantToRegister: "I want to register",
    whoWeAre: {
      title: "Who we are?",
      text: "{kajo} is behind operational maintenance and all active developments of HELFNI. The original idea behind HELFNI comes from a group of students from Košice supported by Society Development Institute and Green Foundation."
    },
    whatWeDo: {
      title: "What we do?",
      text: "{helfni} is a platform linking volunteers with volunteering activities established to raise awareness of where opportunities can help, to mediate effective communication between the volunteer, public and private organizations. It is in these times that more than ever, help is extremely valuable."
    },
    numbers: {
      title: "We are in it TOGETHER",
      volunteers: "volunteers",
      organisations: "organizations",
      offers: "offers to help"
    },
    offerList: {
      title: "Help us",
      notFound: "No offers found",
      showMore: "show more"
    },
    map: {
      title: "Where can you find us?",
      showOnMap: "show on maps"
    },
    motto: "HELFNI - we're helping to help",
    partners: "We cooperate with these organizations",
    footer: {
      socialMedia: "See us on social networks as well",
      disclaimer: "Informations provided by this application are uncensored. Each registered user is solely responsible for the content, the truthfulness and timeliness of informations are exclusively on each registered user."
    }
  },
  event: {
    back: "Back",
    create: "Create your own offer",
    delete: "Delete offer",
    cancel: "Cancel",
    gallery: "Gallery for the event",
    map: "Map",
    errors: {
      endBeforeStart: "The end time is before the start time",
      invalidAddress: "Failed to resolve address. Enter it in a different format or enter a different address."
    },
    dialogs: {
      deleteTitle: "Are you sure?",
      deleteText: "By deleting, offer will be removed from our system and no one will be able to see or react it.",
      unlinkTitle: "Are you sure?",
      unlinkText: "Are you to be unsubscribed from this offer?"
    },
    form: {
      detail: "Detailed description",
      moreInfo: "More details",
      when: "When",
      theseDays: "Whole these days",
      next: "The closest",
      where: "Where",
      howMany: "How many people",
      freePlaces: "Vacancies",
      organizer: "Organizer",
      challenge: "If you are interested in this offer and would like to help, do not hesitate to apply and notify the organization that you are coming to help.",
      typeTitle: "This offer is",
      protected: "protected",
      protectedTooltip: "If you request to join a protected offer, you must wait for the organizer to approve your request."
    },
    join: {
      title: "Join",
      text: "In order to be able to join the offer, you have to ",
      login: "LOG IN",
      requestSent: "Join request sent successfully",
      successful: "Login successful",
      errors: {
        notExists: "This offer is no longer available",
        alreadyJoined: "You have already joined this offer"
      }
    },
    cancelRequest: {
      title: "Cancel my request",
      text: "Cancel the request",
      full: "Full",
      join: "Join",
      successful: "Cancelation was successful"
    },
    edit: {
      title: "Edit offer",
      successful: "Offer changed successfully",
      errors: {
        notExists: "This offer is no longer available",
        notSpecified: "Failed to change data",
        notOwn: "Failed to change data because you do not own this offer",
        time: "The end time is before the start time",
        invalidData: "Invalid form data"
      }
    }
  },
  eventTable: {
    search: "Search",
    createOffer: "Create offer",
    showPastOffers: "Show offers from the past",
    noOffers: "No offers available"
  },
  linkedUsers: {
    title: "Volunteers who joined your offers",
    chooseOffer: "Choose offer",
    noUsers: "No volunteers have joined this offer yet",
    all: "All of them",
    singleOffer: "Volunteers who have joined this offer"
  },
  requests: {
    title: "Volunteers who want to join your protected offers",
    chooseOffer: "Choose offer",
    noOffers: "No offers available",
    offerFull: "This offer is already full of people, you cannot confirm new requests",
    freePlaces: "Number of vacancies",
    confirm: "Confirm participation",
    decline: "Decline participation",
    skills: "Skills",
    desription: "Description",
    send: "Send",
    noRequests: "There are no new requests for the selected offer"
  },
  eventCard: {
    offerFull: "This offer is already full and you cannot participate",
    freePlaces: "There are still vacancies for this offer",
    protectedTooltip: "If you request to join a protected offer, you must wait for the organizer to approve your request."
  },
  eventForm: {
    basicInformation: "Basic information",
    offerTitle: "Name of the offer",
    offerDescription: "Offer description",
    offerType: "Offer type",
    offerTags: "Offer focus",
    offerFreePlaces: "Number of vacancies",
    offerPlace: "Offer place",
    offerPlaceTooltip: "If this offer has the exact address at which it will take place, fill it in and the district will in most cases be assigned automatically. If it does not have an exact address, at least fill in the district.",
    offerAddress: "Event address",
    offerRegion: "District",
    offerTime: {
      title: "Offer time",
      subtitle: "My offers",
      oneDay: "One-day",
      oneDayDescription: "A one-day offer is one that will only take place on one specific day.",
      multiDay: "Multi-day",
      multiDayDescription: "The multi-day offer takes place once, but lasts several days.",
      repeating: "Repeated",
      repeatingDescription: "The repeated offer takes place at regular intervals, for example every Thursday.",
      longTerm: "Long-term",
      longTermDescription: "A long-term offer does not have a precisely specified time, but it takes a longer time.",
      date: "Date of the event",
      startDate: "Start date",
      endDate: "End date",
      chooseDays: "Choose the days on which the offer takes place",
      everyDay: "Every day",
      startTime: "Start time",
      endTime: "Estimated end time",
      allDay: "The offer takes whole day",
      protectedTooltip: "If the offer is protected, you will need to confirm each volunteer request to participate.",
      create: "Create",
      change: "Save changes",
      submit: "Submit"
    }
  },
  ozCard: {
    description: "Description",
    profile: "Profile"
  },
  inviteDialog: {
    invitation: "Invitation",
    invitationText: "Invite this volunteer to one of your free offers",
    notFound: "No offers found",
    cancel: "Cancel",
    confirm: "Comfirm",
    error: "An error has occurred, it is not possible to invite volunteers."
  },
  userCard: {
    description: "Description",
    profile: "Profile",
    invite: "Invite",
    invitationError: "Failed to send invitation."
  },
  offer: {
    longTerm: "Long-term offer",
    allDay: "Whole day",
    joined: "Joined",
    protected: "Protected offer",
    deleted: "This offer has been deleted."
  },
  filter: {
    hide: "Hide filters",
    show: "Show filters",
    clear: "Clear filters",
    region: "Enter the offers region: ",
    regionLabel: "Region",
    type: "Offer type",
    title: "Search by offer name: ",
    titleLabel: "Offer name",
    date: "Choose the offer date: ",
    dateLabel: "Date of offer",
    dateText: "Search by specific days",
    longTerm: "Show long-term offers",
    all: "All offers: ",
    nothingFound: "No offers available"
  },
  deleteAccount: {
    message: "Your account will be automatically deleted unless you cancel the deletion within three days using the link you can find in the email.",
    errors: {
      checksumFailed: "Invalid code. Use the link in the email to delete your account.",
      invalidCode: "This code has already been used, the account is ready to be deleted unless you cancel the deletion."
    }
  },
  deleteAccountCancel: {
    message: "Account deletion successfully canceled.",
    errors: {
      any: "Invalid or already used code. Cancel deletion of the account using the link in your email or the link in the app."
    }
  },
  emailChange: {
    message: "Your account email has been changed successfully.",
    errors: {
      checksumFailed: "Invalid code. Use the link in the email to confirm the email change.",
      invalidCode: "This code has already been used, the email for your account should be changed. Otherwise, contact us at helfni@helfni.sk."
    }
  },
  emailChangeRevert: {
    message: "Email change was successfully undone.",
    errors: {
      checksumFailed: "Invalid code. Use the link in the email to undo the change.",
      invalidCode: "This code has already been used, the email change to your account should be undone. Otherwise, contact us at helfni@helfni.sk."
    }
  },
  mailSubscription: {
    cancelMessage: "Successfully unsubscribed from receiving emails."
  },
  dashboard: {
    created: "Offer created successfully",
    offers: "Your offers"
  },
  appHome: {
    recommended: "Recommended offers: ",
    next: "My next offers: ",
    none: "You are not currently joined to any offers.",
    search: "Search for the offers",
    awaits: "Awaiting approval: "
  },
  map: {
    filters: {
      hide: "Hide filters",
      show: "Show filters",
      clear: "Clear"
    },
    region: {
      label: "Region",
      text: "Enter the region in which you want offers: "
    },
    tag: {
      label: "Offer focus"
    },
    name: {
      label: "Offer name",
      text: "Search offer by name: "
    },
    date: {
      label: "Date of offer",
      textCheckbox: "Search by specific days",
      textFilter: "Choose the days for offers: "
    },
    longTerm: {
      label: "Show long-term offers"
    },
    marker: {
      moreInfo: "More info",
      tag: {
        free: "Needs volunteers",
        linked: "No volunteers are needed",
        requested: "Requested to join"
      }
    }
  },
  organisations: {
    filters: {
      hide: "Hide filters",
      show: "Show filters",
      clear: "Clear"
    },
    region: {
      label: "Region",
      text: "Enter the region in which you want offers: "
    },
    nameOrDescription: {
      label: "Name or description",
      text: "Search for organizations by name or description: "
    },
    notFound: {
      text: "No organizations found"
    }
  },
  profile: {
    title: "My profile",
    type: {
      organization: "Organization",
      volunteer: "Volunteer"
    },
    delete: {
      label: "Are you sure?",
      text: "After entering the password, we will send you an email with a link that will allow you to confirm the deletion of your account. After 3 days of confirmation, your account will be permanently deleted along with all your offers. If the email does not arrive immediately, please wait a few minutes and check the spam folder. If you still haven't received the email, please contact us by email at helfni@helfni.sk.",
    },
    changePassword: {
      label: "Change password",
      text: "Enter your old password and then your new password.",
      oldPassword: "Old password",
      newPassword: "New password",
      repeatPassword: "Repeat new password",
      actions: {
        cancel: "Cancel",
        confirm: "Confirm"
      }
    },
    changeEmail: {
      label: "Change email",
      text: "Enter a password and a new email. We will send you a code to confirm the email and complete the change. If you would like to revert this change, it is possible within 7 days by clicking on the link that you will receive in your old email.",
      password: "Password",
      newEmail: "New email",
      actions: {
        cancel: "Cancel",
        confirm: "Confirm"
      }
    },
    info: {
      photo: "Upload photo",
      user: {
        label: "Volunteer",
        name: "Name",
        region: "Region",
        username: "Username",
        email: "Email"
      },
      organisation: {
        label: "Organization",
        name: "Name of the organization",
        username: "Username",
        email: "Email",
        phone: "Phone number",
        address: "Address"
      },
      description: "Description",
    },
    edit: {
      password: "Change password",
      email: "Change email",
      delete: "Delete account",
      editProfile: "Change profile",
      save: "Save",
      cancel: "Cancel"
    },
    unsubscribe: "I don't want to receive emails with new offers",
    messages: {
      notLoggedIn: "You must be logged in to view volunteer profiles.",
      notFound: "Profile not found",
      photoInvalidPicture: "Upload an image as your profile photo",
      photoInvalidFile: "Upload valid file",
      photoFileTooBig: "Profile photo is too large, upload smaller file.",
      wrongPassword: "Invalid password",
      passwordChanged: "Password changed successfully",
      emailChangeSent: "Email to confirm changes sent",
      subscriptionChanged: "Email subsciption changed"
    }
  },
  users: {
    inviteSent: "Invitation sent successfully",
    filters: {
      hide: "Hide filters",
      show: "Show filters",
      clear: "Clear"
    },
    region: {
      label: "Region",
      text: "Enter the region in which you are looking for volunteers: "
    },
    tags: {
      label: "Skills of volunteers"
    },
    nameOrDescription: {
      label: "Name or description",
      text: "Search for volunteers by name or description: "
    },
    notFound: {
      text: "No volunteers found"
    }
  },
  browserNotSupported: {
    label: "Sorry, this browser is not supported.",
    text: "Please use one of the newer browsers or our mobile application."
  },
  warningDeleteAcc: {
    label: "DELETED ACCOUNT",
    text: " this account and all associated data will be deleted within three days. Deletion can be canceled by ",
    link: "this link."
  },
  routes: {
    landing: "Helfni",
    home: "Home",
    map: "Map of offers",
    users: "List of volunteers",
    organisations: "List of organizations",
    eventsList: "List of offers",
    eventCreate: "Create offer",
    profile: "Profile",
    dashboard: "Home",
    login: "Login",
    register: "Registration",
    activateEmail: "Email activation",
    recoverPassword: "Password recovery",
    deleteAccount: "Account deletion",
    changeEmail: "Change email",
    logout: "Logout",
    terms: "Terms of use",
    unsubscribe: "Unsubscribe",
    notSupported: "Unsupported browser"
  },
  inputRules: {
    required: "This field is required",
    minLength6: "The minimum length is 6 characters",
    maxLength1000: "The maximum length is 1000 characters",
    maxLength100: "The maximum length is 100 characters",
    maxLength80: "The maximum length is 80 characters",
    maxLength64: "The maximum length is 64 characters",
    maxLength32: "The maximum length is 32 characters",
    usernameAt: "Login name cannot contain @ sign",
    wrongEmail: "Invalid email address",
    samePassword: "Passwords do not match",
    wrongPhone: "Enter a valid phone number",
    notNumber: "Enter a numeric value",
    notInteger: "Enter a positive value",
    numberSize: "Please enter a number less than 10000000",
    dateFormat: "Please enter a valid date in the format DD.MM.YYYY",
    timeFormat: "Please enter a valid time in the format HH:MM",
    dateFuture: "Enter valid date in the future",
    dateDifference: "End date must be later than start date",
    timeFuture: "Enter valid time in the future",
    timeDifference: "End time must be later than start time",
    photoType: "Unsupported image format",
    fileSize: "The maximum file size is 1 MB"
  },
  tags: {
    health: {
      name: "Health",
      description: "Epidemiologists, public health, health statisticians, the health supply chain, doctors, nurses, laboratory technicians, medical, biology and chemistry students, medical waste management."
    },
    management: {
      name: "Management",
      description: "Emergency management and coordination, emergency communication, marketing and media, information management, data analysis, monitoring, evaluation and reporting."
    },
    food: {
      name: "Food",
      description: ""
    },
    distribution: {
      name: "Distribution",
      description: "Transportation, delivery and loading of goods."
    },
    education: {
      name: "Education",
      description: "Pedagogy, child protection, counseling."
    },
    children: {
      name: "Children",
      description: ""
    },
    seniors: {
      name: "Seniors",
      description: ""
    },
    casework: {
      name: "Casework",
      description: "Psychosocial support and counseling and social work."
    },
    socialHelp: {
      name: "Social help",
      description: "Socio-economic and health services, anthropology, sociology."
    },
    sewing: {
      name: "Sewing",
      description: "Sewing of medical masks, protective suits and other auxiliary material."
    },
    communityWork: {
      name: "Community work",
      description: "Gastronomy, procurement, livelihood and city care."
    }
  },
  eventPresets: {
    psychosocialHelp: {
      name: "Psychosocial help"
    },
    teachingChildren: {
      name: "Teaching children online (teachers, cultural officers, actors)"
    },
    telephony: {
      name: "Telephony (administration and organization)"
    },
    disinfection: {
      name: "Disinfection (experience with sprays and chemicals)"
    },
    sewingDrapes: {
      name: "Sewing medical masks"
    },
    mobileLaboratory: {
      name: "Mobile laboratories (medical students)"
    },
    collectionOfMedicines: {
      name: "Picking up of medicines in the pharmacy"
    },
    shoppingForSeniors: {
      name: "Shopping for seniors, single parents or quarantined people"
    },
    distribution: {
      name: "Delivery (food, grocery, medicine, etc.) for the elderly or otherwise disadvantaged people"
    },
    medicalDistribution: {
      name: "Distribution of medical and protective material"
    },
    writingOrders: {
      name: "Writing orders to buy essential food"
    },
    foodDelivery: {
      name: "Delivery of food and orders"
    },
    assistanceDelivery: {
      name: "Delivery of material assistance"
    },
    other: {
      name: "Other"
    }
  }
}