import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

import { store } from '@/store/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing',
    component: () => import('../views/Home.vue'),
    meta: {
      title: 'routes.landing'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/app',
    component: () => import('../views/WebApp.vue'),
    children: [
      {
        path: 'home',
        name: 'WebAppHome',
        component: () => import('../views/WebApp/WebAppHome.vue'),
        alias: '',
        meta: {
          requiresAuth: true,
          title: 'routes.dashboard'
        },
      },
      {
        path: 'map',
        name: 'WebAppMap',
        component: () => import ('../views/WebApp/WebAppMap.vue'),
        meta: {
          requiresAuth: true,
          public: true,
          title: 'routes.map'
        },
      },
      {
        path: 'users',
        name: 'WebAppUsers',
        component: () => import ('../views/WebApp/WebAppUsers.vue'),
        meta: {
          requiresAuth: true,
          title: 'routes.users',
          public: true
        },
      },
      {
        path: 'organisations',
        name: 'WebAppOrganisations',
        component: () => import ('../views/WebApp/WebAppOrganisations.vue'),
        meta: {
          requiresAuth: true,
          title: 'routes.organisations'
        },
      },
      {
        path: 'events',
        component: () => import('../views/WebApp/WebAppEvents.vue'),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'WebAppEventHome',
            component: () => import('../views/WebApp/events/WebAppEventHome.vue'),
            meta: {
              title: 'routes.eventsList',
              public: true
            }
          },
          {
            path: 'create',
            name: 'WebAppEventCreate',
            component: () => import('../views/WebApp/events/WebAppEventCreate'),
            meta: {
              title: 'routes.eventCreate',
            }
          },
          {
            path: ':id',
            name: 'WebAppEventDetail',
            component: () => import('../views/WebApp/events/WebAppEventDetail.vue'),
            meta: {
              public: true
            }
          }
        ]
      },
      {
        path: 'profile/:id',
        name: 'WebAppProfile',
        component: () => import('../views/WebApp/WebAppProfile.vue'),
        meta: {
          requiresAuth: true,
          title: 'routes.profile',
          public: true
        },
      },
      /* {
        path: 'settings',
        name: 'WebAppSettings',
        component: () => import('../views/WebApp/WebAppSettings.vue'),
        meta: {
          requiresAuth: true,
        },
      }, */
      {
        path: 'dashboard',
        name: 'WebAppDashboard',
        component: () => import('../views/WebApp/WebAppDashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'routes.dashboard'
        },
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/WebApp/auth/Login.vue'),
    meta: {
      requiresVisitor: true,
      title: 'routes.login'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/WebApp/auth/Register.vue'),
    meta: {
      requiresVisitor: true,
      title: 'router.gerister'
    }
  },
  {
    path: '/activate/:code',
    name: 'activateEmail',
    component: () => import('../views/WebApp/auth/ActivateEmail.vue'),
    meta: {
      title: 'routes.activateEmail'
    }
  },
  {
    path: '/recover',
    name: 'recover',
    component: () => import('../views/WebApp/auth/PasswordRecoveryForm.vue'),
    meta: {
      title: 'routes.recoverPassword'
    }
  },
  {
    path: '/recovercode/:code',
    name: 'recoverCode',
    component: () => import('../views/WebApp/auth/PasswordRecoveryCode.vue'),
    meta: {
      title: 'routes.recoverPassword'
    }
  },
  {
    path: '/recover/success',
    name: 'recover_success',
    component: () => import('../views/WebApp/auth/PasswordRecoverySuccess.vue'),
    meta: {
      title: 'routes.recoverPassword'
    }
  },
  {
    path: '/delete/:code',
    name: 'deleteAccountCode',
    component: () => import('../views/WebApp/DeleteAccountCode.vue'),
    meta: {
      title: 'routes.deleteAccount'
    }
  },
  {
    path: '/cancel_delete',
    name: 'cancelDelete',
    component: () => import('../views/WebApp/WebAppCancelDelete.vue'),
    meta: {
      requiresAuth: true,
      title: 'routes.deleteAccount'
    }
  },
  {
    path: '/emailchange/:code',
    name: 'emailChange',
    component: () => import('../views/WebApp/EmailChange.vue'),
    meta: {
      title: 'routes.changeEmail'
    }
  },
  {
    path: '/emailchangerevert/:code',
    name: 'emailChangeRevert',
    component: () => import('../views/WebApp/EmailChangeRevert.vue'),
    meta: {
      title: 'routes.changeEmail'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/WebApp/auth/Logout.vue'),
    meta: {
      title: 'routes.logout'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/WebApp/WebAppTerms.vue'),
    meta: {
      title: 'routes.terms'
    }
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: () => import('../views/WebApp/MailSubscription.vue'),
    meta: {
      requiresAuth: true,
      title: 'routes.unsubscribe'
    }
  },
  {
    path: '*',
    redirect: '/app/events'
  },
  {
    path: '/notsupported',
    name: 'NotSupported',
    component: () => import('../views/NotSupported.vue'),
    meta: {
      title: 'routes.notSupported'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  //document.title = to.meta.title || 'Helfni'
  
  const isIE = /*@cc_on!@*/false || !!document.documentMode;

  if (to.name != 'NotSupported' && isIE) {
    next({
      name: 'NotSupported'
    })
  }
  
  if (to.name == 'WebAppHome' && store.state.loggedInFlag == '1') {
    next({
      name: 'WebAppDashboard'
    })
  }
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isPublic = to.matched.some(record => record.meta.public)

    if (!await checkToken(isPublic) && !isPublic) {
      next({
        name: 'login',
        query: {
          nextUrl: to.fullPath,
       }
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        name: 'WebAppHome',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

async function checkToken(isPublic) {
  var res = null

  if (store.state.token) {
    axios.defaults.headers.common['auth-token'] = store.state.token;

    res = await axios.post('/auth/user').catch(() => {
      !isPublic && router.push({name: 'logout'})
    })
  }

  if (res && res.data && res.data.flag) {
    store.commit('setLoggedInFlag', res.data.flag)
    store.commit('niecoIneAkoSetloggedInId', res.data.ID)
    
    store.commit('setName', res.data.name || res.data.title)
    localStorage.setItem('name', res.data.name || res.data.title)
    
    store.commit('setRegion', res.data.region)
    localStorage.setItem('region', res.data.region)
    
    const photo = res.data.photo
    localStorage.setItem('photo', photo)
    store.commit('setPhoto', photo)
    store.commit('setDeleteDate', res.data.deleteDate)

    return true
  } else {
    store.commit('destroyLoggedInFlag')
    return false
  }
}

axios.interceptors.response.use( (response) => {
  return response;
}, (error) => {
  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if (error.response.data == 'Access denied') {
    
    router.push({ name: 'logout' });

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  return store.dispatch('REFRESH_TOKEN')
    .then((token) => {
      const config = error.config;
      config.headers['auth-token'] = token;
      return new Promise((resolve, reject) => {
        axios.request(config).then(response => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });
    })
    .catch((error) => {
      Promise.reject(error);
    });
});

export default router