export default {
  language: "Мова",
  cancel: "Скасувати",
  popup: {
    password: "Пароль",
    cancel: "Скасувати",
    confirm: "Підтвердити"
  },
  data: {
    myRegion: "Мій район",
    allRegions: "Усі райони",
    noData: "Немає даних",
  },
  errors: {
    errorTitle: "Помилка",
    back: "Назад на головну сторінку",
    form: "Неправильно заповнена форма",
    address: "Недійсна адреса",
    atLeastOneDay: "Виберіть принаймні один день",
    errorText: "Перепрошуємо, з нашого боку виникла помилка і ми не змогли впоратися з цим запитом. Будь-ласка спробуйте пізніше. Якщо помилка не зникає, зв’яжіться з нами за адресою helfni@helfni.sk."
  },
  activeEmail: {
    text: "Адресу електронної пошти успішно підтверджено. Маєте змогу",
    login: "увійти",
    invalidCode: "Невірний код. Скористайтеся посиланням у листі, щоб активувати свій обліковий запис.",
    alreadyConfirmed: "Адреса вашої електронної пошти вже підтверджена, ви можете увійти."
  },
  login: {
    confirm: "Підтвердити адресу вашої електронної пошти за посиланням у листі, який ми надіслали на вашу електронну адресу. Після підтвердження ви зможете увійти. Якщо лист не надійде найближчим часом, зачекайте кілька хвилин і перевірте папку зі спамом. Якщо ви все ще не отримали електронного листа, зв’яжіться з нами за адресою helfni@helfni.sk.",
    register: "Щоб переглянути список волонтерів, спочатку потрібно зареєструватися та увійти як організація.",
    welcome: "Ласково просимо!",
    username: "Ім'я для входу або адреса вашої електронної пошти",
    password: "Пароль",
    forgot: "Я забув/забула свій пароль",
    login: "Увійти",
    noAccount: "Немаєте обліковий запис?",
    createAccount: "Створіть новий",
    incorrectPassword: "Невірний пароль",
    userNotFound: "Неправильне ім’я або електронна адреса для входу",
    emailNotVerified: "Підтвердити адресу вашої електронної пошти за посиланням у листі, який ми надіслали на вашу електронну адресу, аби мати змогу увійти"
  },
  passwordRecovery: {
    recover: "Відновлення паролю",
    enterNewPassword: "Введіть новий пароль",
    newPassword: "Новий пароль",
    changePassword: "Змінити пароль",
    codeUsed: "Цей код скидання пароля вже використано.",
    invalidCode: "Невірний код",
    inputEmail: "Введіть адресу своєї електронної пошти нижче, і якщо вона збігається з електронною поштою, пов’язаною з вашим обліковим записом Helfni, ми надішлемо вам посилання для скидання пароля",
    email: "Email",
    messageSent: "Якщо адреса електронної пошти, яку ви ввели - правильна, ви отримаєте посилання для скидання пароля. Якщо лист не надійде найближчим часом, зачекайте кілька хвилин і перевірте папку зі спамом. Якщо ви все ще не отримали електронного листа, зв’яжіться з нами за адресою helfni@helfni.sk.",
    sendEmail: "Надіслати електронний лист для відновлення",
    success: "Пароль успішно змінено. Маєте змогу",
    login: "увійти"
  },
  register: {
    title: "Запеєструйся в HELFNI та допомагай!",
    accountType: {
      title: "Виберіть один із двох типів облікових записів",
      volunteer: "Волонтер",
      volunteerDescription: "За допомогою цього облікового запису ви зможете вибирати пропозиції від організацій, яким хочете допомогти.",
      organisation: "Організація",
      organisationDescription: "За допомогою цього облікового запису ви зможете створювати пропозиції, до яких можуть подаватись люди, які бажають допомогти."
    },
    continue: "Далі",
    registration: "Реєстрація",
    back: "Назад",
    personalInformation: {
      title: "Персональна інформація",
      name: "Ім'я і прізвище",
      email: "Email",
      region: "Мій район",
      descriptionText: "Напишіть щось про себе в цьому розділі. Чи маєте якийсь попередній досвід волонтерства, яка ваша мотивація Helfn'ути своєму місту, та все, що ви вважаєте важливим для себе. Цей опис бачать організації, які можуть активно шукати волонтерів, і, отже, уведена інформація може збільшити ваші шанси на те, що організація вибере вас.",
      descriptionLabel: "Опишіть себе. Зокрема - посилання на попередній досвід",
      skills: "Мої навички, знання та вміння"
    },
    organisation: {
      title: "Інформація про організацію",
      name: "Назва організації",
      address: "Точна адреса",
      region: "Район",
      description: "Опис організації",
      contactTitle: "Контактна інформація",
      phone: "Номер телефону",
      email: "Email"
    },
    loginDetails: {
      title: "Оберіть параметри для входу",
      username: "Ім'я користувача",
      password: "Пароль",
      repeatPassword: "Повторіть пароль"
    },
    consent: "Я погоджуюся з",
    terms: "умовами користування",
    register: "Зареєструватися",
    errors: {
      usernameTaken: "Користувач із таким логіном вже існує",
      emailTaken: "Користувач із цією електронною адресою вже існує",
      fixErrors: "Будь ласка, виправте всі помилки нижче"
    }
  },
  navbar: {
    dashboard: "Огляд",
    home: "Головна",
    offers: "Пропозиції",
    map: "Мапа",
    volunteers: "Волонтери",
    organisations: "Організації",
    logout: "Вийти",
    login: "Увійти",
    register: "Зареєструватися",
    profile: "Профіль"
  },
  landing: {
    title: "своєму місту",
    subtitle: "зараз є потреба ще більше, ніж будь-коли.",
    wantToHelp: "Ви хочете допомогти?",
    seeOffers: "Дивіться пропозиції",
    howToHelp: {
      title: "Як допомогти разом з HELFNI?",
      register: "Зареєструйтеся тут або в додатку",
      choose: "Виберіть пропозиції, з якими ви хочете допомогти, або створіть ці пропозиції",
      help: "Залиште слід поруч",
    },
    iWantToRegister: "Я хочу зареєструватися",
    whoWeAre: {
      title: "Хто ми є?",
      text: "{kajo} стоїть за експлуатацією, підтримкою та активним розвитком HELFNI. Оригінальна ідея HELFNI походить від групи студентів з Кошице за підтримки Інституту розвитку суспільства та Green Foundation."
    },
    whatWeDo: {
      title: "Що ми робимо?",
      text: "{helfni} – це платформа, яка об’єднує волонтерів із волонтерською діяльністю, яка створена для підвищення обізнаності про можливості допомогти, для ефективної комунікації між волонтерами, державними та приватними організаціями. Саме в ці часи, як ніколи, допомога дуже цінується."
    },
    numbers: {
      title: "Ми - РАЗОМ",
      volunteers: "волонтерів",
      organisations: "організацій",
      offers: "пропозицій допомоги"
    },
    offerList: {
      title: "Helfni нам",
      notFound: "Пропозицій не знайдено",
      showMore: "відобразити більше"
    },
    map: {
      title: "Де зможете нас знайти?",
      showOnMap: "відобразити на мапі"
    },
    motto: "HELFNI - Допомагаємо вам допомагати",
    partners: "Ми співпрацюємо з цими організаціями",
    footer: {
      socialMedia: "Завітайте до нас у соціальних мережах",
      disclaimer: "Інформація, надана цією програмою, не піддається цензурі. Кожен зареєстрований користувач несе повну відповідальність за зміст, правдивість та своєчасність інформації профілю."
    }
  },
  event: {
    back: "Назад",
    create: "Створи власну пропозицію",
    delete: "Видалити пропозицію",
    cancel: "Скасувати",
    gallery: "Галерея події",
    map: "Мапа",
    errors: {
      endBeforeStart: "Час завершення - перед часом початку",
      invalidAddress: "Не вдалося розпізнати адресу. Введіть її в іншому форматі або введіть іншу адресу."
    },
    dialogs: {
      deleteTitle: "Ви впевнені?",
      deleteText: "Якщо видалити пропозицію, пропозицію буде видалено з нашої системи, і ніхто не зможе її побачити та відреагувати.",
      unlinkTitle: "Ви впевнені",
      unlinkText: "Ви впевнені, що хочете скасувати підписку на цю пропозицію?"
    },
    form: {
      detail: "Детальний опис",
      moreInfo: "Більше інформації",
      when: "Коли",
      theseDays: "Досі триває",
      next: "Найблищі",
      where: "Де",
      howMany: "Слільки людей",
      freePlaces: "Вільних місць",
      organizer: "Організатор",
      challenge: "Якщо ви зацікавилися цією пропозицією і хочете допомогти, не вагайтеся подати заявку та повідомити організацію, що ви збираєтеся допомогти.",
      typeTitle: "Ця пропозиція є",
      protected: "захищена",
      protectedTooltip: "Якщо ви подаєте запит на приєднання до захищеної пропозиції, ви повинні зачекати, поки організатор схвалить ваш запит."
    },
    join: {
      title: "Приєднатися",
      text: "Щоб приєднатися до пропозиції, потрібно ",
      login: "Увійти",
      requestSent: "Запит на приєднання успішно надісланий",
      successful: "Вхід успішно виконаний",
      errors: {
        notExists: "Ця пропозиція більше не актуальна",
        alreadyJoined: "Ви вже приєдналися до цієї пропозиції"
      }
    },
    cancelRequest: {
      title: "Скасувати запит",
      text: "Скасуйте запит",
      full: "Заповнено",
      join: "Приєднатися",
      successful: "Вихід пройшов успішно"
    },
    edit: {
      title: "Редагувати пропозицію",
      successful: "Пропозиція успішно змінена",
      errors: {
        notExists: "Ця пропозиція більше не актуальна",
        notSpecified: "Не вдалося змінити дані",
        notOwn: "Дані не можна змінити, оскільки ви не є власником цієї пропозиції",
        time: "Час завершення - перед часом початку",
        invalidData: "Форма має неправильні дані"
      }
    }
  },
  eventTable: {
    search: "Шукати",
    createOffer: "Створити пропозицію",
    showPastOffers: "Відображувати минулі пропозиції",
    noOffers: "Немає пропозицій"
  },
  linkedUsers: {
    title: "Волонтери, які підписалися на ваші пропозиції",
    chooseOffer: "Виберіть пропозицію",
    noUsers: "На цю пропозицію поки що не підписався жоден волонтер",
    all: "Усі",
    singleOffer: "Волонтери, які підписалися на цю пропозицію"
  },
  requests: {
    title: "Волонтери, які подали заявку на ваші захищені пропозиції",
    chooseOffer: "Виберіть пропозицію",
    noOffers: "Немає пропозицій",
    offerFull: "Ця пропозиція вже заповнена, ви не можете підтвердити нові запити",
    freePlaces: "Кількість вакансій",
    confirm: "Підтвердити участь",
    decline: "Відмовитися від участі",
    skills: "Навички",
    desription: "Опис",
    send: "Надіслати",
    noRequests: "Наразі немає нових запитів на вибрану пропозицію"
  },
  eventCard: {
    offerFull: "Ця пропозиція вже заповнена, і ви не можете на неї зареєструватися",
    freePlaces: "На цю пропозицію ще є вільні місця",
    protectedTooltip: "Якщо ви подаєте запит на приєднання до захищеної пропозиції, ви повинні зачекати, поки організатор схвалить ваш запит."
  },
  eventForm: {
    basicInformation: "Основна інформація",
    offerTitle: "Назва пропозиції",
    offerDescription: "Опис пропозиції",
    offerType: "Тип пропозиції",
    offerTags: "Мітки пропозиції",
    offerFreePlaces: "Кількість вакансій",
    offerPlace: "Місце пропозиції",
    offerPlaceTooltip: "Якщо ця пропозиція має точну адресу, за якою вона відбуватиметься, заповніть її, і район у більшості випадків буде призначено автоматично. Якщо в ньому немає точної адреси, позначте хоча б район.",
    offerAddress: "Адреса події",
    offerRegion: "Район",
    offerTime: {
      title: "Час пропозиції",
      subtitle: "Моя пропозиція є",
      oneDay: "Одного дня",
      oneDayDescription: "Одноденна пропозиція – це пропозиція, яка діє лише в один певний день.",
      multiDay: "Багатоденна",
      multiDayDescription: "Багатоденна пропозиція діє один раз, але триває кілька днів.",
      repeating: "Повторюється",
      repeatingDescription: "Регулярна пропозиція, яка діє через регулярні проміжки часу. Наприклад, щочетверга.",
      longTerm: "Довгострокова",
      longTermDescription: "Довгострокова пропозиція не має точно визначеного часу виконання, але вимагає більше часу.",
      date: "Дата події",
      startDate: "Дата початку",
      endDate: "Дата закінчення",
      chooseDays: "Виберіть дні, в які відбувається пропозиція",
      everyDay: "Кожен день",
      startTime: "Час початку",
      endTime: "Приблизний час завершення",
      allDay: "Пропозиція відбувається протягом дня",
      protectedTooltip: "Якщо пропозиція захищена, вам потрібно буде підтверджувати кожен запит волонтера на участь у цій події.",
      create: "Створити",
      change: "Зберегти зміни",
      submit: "Надіслати"
    }
  },
  ozCard: {
    description: "Опис",
    profile: "Профіль"
  },
  inviteDialog: {
    invitation: "Запрошення",
    invitationText: "Запросіть цього волонтера до однієї зі своїх вільних пропозицій",
    notFound: "Пропозицій не знайдено",
    cancel: "Скасувати",
    confirm: "Підтвердити",
    error: "Сталася помилка, неможливо запросити волонтерів."
  },
  userCard: {
    description: "Опис",
    profile: "Профіль",
    invite: "Запросити",
    invitationError: "Не вдалося надіслати запрошення."
  },
  offer: {
    longTerm: "Довгострокова пропозиція",
    allDay: "Протягом цілого дня",
    joined: "Тих, хто увійшов",
    protected: "Захищена пропозиція",
    deleted: "Táto ponuka bola vymazaná."
  },
  filter: {
    hide: "Приховати фільтри",
    show: "Показати фільтри",
    clear: "Очистити фільтри",
    region: "Введіть район, у якому ви хочете отримати пропозиції: ",
    regionLabel: "Район",
    type: "Мітки пропозиції",
    title: "Шукати пропозицію за назвою: ",
    titleLabel: "Назва пропозиції",
    date: "Виберіть дні, в які вам потрібні пропозиції: ",
    dateLabel: "Дата події",
    dateText: "Шукати в певні дні",
    longTerm: "Показати довгострокові пропозиції",
    all: "Усі пропозиції: ",
    nothingFound: "Пропозицій не знайдено"
  },
  deleteAccount: {
    message: "Ваш обліковий запис буде автоматично видалено, якщо ви не скасуєте видалення протягом трьох днів за посиланням, яке ви знайдете в електронному листі.",
    errors: {
      checksumFailed: "Невірний код. Використовуйте посилання в електронному листі, щоб видалити свій обліковий запис.",
      invalidCode: "Цей код уже використано, обліковий запис готовий до видалення, якщо ви не скасуєте видалення."
    }
  },
  deleteAccountCancel: {
    message: "Видалення облікового запису успішно скасовано.",
    errors: {
      any: "Недійсний або вже використаний код. Відновіть видалення облікового запису, використовуючи посилання в електронному листі або посилання в додатку."
    }
  },
  emailChange: {
    message: "Адресу електронної пошти вашого облікового запису було успішно змінено.",
    errors: {
      checksumFailed: "Невірний код. Скористайтеся посиланням у листі, щоб підтвердити зміну електронної пошти.",
      invalidCode: "Цей код уже використано, адреса електронної пошти для вашого облікового запису мала би бути вже змінена. В іншому випадку зв’яжіться з нами за адресою helfni@helfni.sk."
    }
  },
  emailChangeRevert: {
    message: "Зміну адреси електронної пошти успішно скасовано.",
    errors: {
      checksumFailed: "Невірний код. Скористайтеся посиланням у електронному листі, щоб скасувати зміни.",
      invalidCode: "Цей код уже використано, зміна адреси електронної пошти у вашому обліковому записі мала би бути вже скасувана. В іншому випадку зв’яжіться з нами за адресою helfni@helfni.sk."
    }
  },
  mailSubscription: {
    cancelMessage: "Успішно скасовано підписку на електронні листи сповіщень."
  },
  dashboard: {
    created: "Пропозиція створена успішно",
    offers: "Ваші пропозиції"
  },
  appHome: {
    recommended: "Рекомендовані пропозиції: ",
    next: "Мої наступні пропозиції: ",
    none: "Наразі ви не приєдналися до жодної пропозиції.",
    search: "Пошук пропозицій",
    awaits: "Очікують схвалення: "
  },
  map: {
    filters: {
      hide: "Приховати фільтри",
      show: "Показати фільтри",
      clear: "Очистити фільтри"
    },
    region: {
      label: "Район",
      text: "Введіть район, у якому ви хочете отримати пропозиції: "
    },
    tag: {
      label: "Мітки пропозиції"
    },
    name: {
      label: "Назва пропозиції",
      text: "Шукайти пропозицію за назвою: "
    },
    date: {
      label: "Дата події",
      textCheckbox: "Шукати в певні дні",
      textFilter: "Виберіть дні, в які вам потрібні пропозиції: "
    },
    longTerm: {
      label: "Показати довгострокові пропозиції"
    },
    marker: {
      moreInfo: "Натисніть щоб дізнатися більше",
      tag: {
        free: "Потребує волонтерів",
        linked: "Непотребує волонтерів",
        requested: "Має запити на схвалення"
      }
    }
  },
  organisations: {
    filters: {
      hide: "Приховати фільтри",
      show: "Показати фільтри",
      clear: "Очистити фільтри"
    },
    region: {
      label: "Район",
      text: "Введіть район, у якому ви шукаєте організації: "
    },
    nameOrDescription: {
      label: "Назва або опис",
      text: "Пошук організацій за назвою або описом: "
    },
    notFound: {
      text: "Не знайдено жодної організації"
    }
  },
  profile: {
    title: "Мій профіль",
    type: {
      organization: "Організація",
      volunteer: "Волонтер"
    },
    delete: {
      label: "Ви впевнениі?",
      text: "Після введення пароля ми надішлемо вам електронного листа з посиланням, яке дозволить підтвердити видалення облікового запису. Через 3 дні після підтвердження ваш обліковий запис буде назавжди видалено разом з усіма вашими пропозиціями. Якщо лист не надійшов негайно, зачекайте кілька хвилин і перевірте папку зі спамом. Якщо ви все ще не отримали листа, зв’яжіться з нами електронною поштою helfni@helfni.sk.",
    },
    changePassword: {
      label: "Зміна пароля",
      text: "Введіть свій старий пароль, а потім новий пароль.",
      oldPassword: "Старий пароль",
      newPassword: "Новий пароль",
      repeatPassword: "Повторіть новий пароль",
      actions: {
        cancel: "Скасувати",
        confirm: "Підтвердити"
      }
    },
    changeEmail: {
      label: "Зміна електронної пошти",
      text: "Введіть ваш діючий пароль і нову адресу електронної пошти. Ми надішлемо вам код для підтвердження нової адреси та завершення зміни. Якщо ви бажаєте скасувати цю зміну, це можливо протягом 7 днів, натиснувши посилання, яке ви отримаєте на адресу старої електронної пошти.",
      password: "Пароль",
      newEmail: "Нова адреса",
      actions: {
        cancel: "Скасувати",
        confirm: "Підтвердити"
      }
    },
    info: {
      photo: "Завантажте фото",
      user: {
        label: "Волонтер",
        name: "Ім'я",
        region: "Район",
        username: "Ім'я користувача",
        email: "Email"
      },
      organisation: {
        label: "Організація",
        name: "Назва організації",
        username: "Ім'я користувача",
        email: "Email",
        phone: "Номер телефону",
        address: "Адреса"
      },
      description: "Опис",
    },
    edit: {
      password: "Змінити пароль",
      email: "Змінити адресу електронної пошти",
      delete: "Видалити аккаунт",
      editProfile: "Редагувати профіль",
      save: "Зберегти зміни",
      cancel: "Скасувати"
    },
    unsubscribe: "Я не хочу отримувати електронні листи про нові пропозиції",
    messages: {
      notLoggedIn: "Ви повинні увійти, щоб переглянути профілі волонтерів.",
      notFound: "Профіль не знайдено",
      photoInvalidPicture: "Завантажте дійсне зображення як фотографію профілю",
      photoInvalidFile: "Завантажте дійсний файл",
      photoFileTooBig: "Фото профілю завелике, завантажте менший файл.",
      wrongPassword: "Недійсний пароль",
      passwordChanged: "Пароль успішно змінено",
      emailChangeSent: "Надіслано електронний лист на підтвердження зміни",
      subscriptionChanged: "Налаштування відправки електронних листів успішно змінені"
    }
  },
  users: {
    inviteSent: "Запрошення успішно надіслано",
    filters: {
      hide: "Приховати фільтри",
      show: "Показати фільтри",
      clear: "Очистити фільтри"
    },
    region: {
      label: "Район",
      text: "Введіть район, у якому ви шукаєте волонтерів: "
    },
    tags: {
      label: "Навички волонтерів"
    },
    nameOrDescription: {
      label: "Назва або опис",
      text: "Шукайти волонтерів за іменем або описом: "
    },
    notFound: {
      text: "Волонтерів не знайдено"
    }
  },
  browserNotSupported: {
    label: "На жаль, цей браузер не підтримується.",
    text: "Будь ласка, використовуйте один із новіших браузерів або наш мобільний додаток."
  },
  warningDeleteAcc: {
    label: "ВИДАЛЕНИЙ ОБЛІКОВИЙ ЗАПИС",
    text: " цей обліковий запис і всі пов’язані з ним дані будуть видалені протягом трьох днів. Видалення можна скасувати ",
    link: "за цим посиланням."
  },
  routes: {
    landing: "Helfni",
    home: "Головна",
    map: "Пропозиції на мапі",
    users: "Перелік волонтерів",
    organisations: "Перелік організацій",
    eventsList: "Перелік пропозицій",
    eventCreate: "Створення пропозиції",
    profile: "Профіль",
    dashboard: "Огляд",
    login: "Вхід",
    register: "Реєстрація",
    activateEmail: "Активація адреси електронної пошти",
    recoverPassword: "Відновлення паролю",
    deleteAccount: "Видалення облікового запису",
    changeEmail: "Зміна адреси електронної пошти",
    logout: "Вийти",
    terms: "Умови користування",
    unsubscribe: "Налаштування електронної пошти",
    notSupported: "Ваш браузер не підтримується"
  },
  inputRules: {
    required: "Це поле є обов'язковим",
    minLength6: "Мінімальна довжина становить 6 символів",
    maxLength1000: "Максимальна довжина становить 1000 символів",
    maxLength100: "Максимальна довжина становить 100 символів",
    maxLength80: "Максимальна довжина становить 80 символів",
    maxLength64: "Максимальна довжина становить 64 символа",
    maxLength32: "Максимальна довжина становить 32 символа",
    usernameAt: "Ім’я для входу не може містити @",
    wrongEmail: "Невірна адреса електронної пошти",
    samePassword: "Паролі не співпадають",
    wrongPhone: "Введіть дійсний номер телефону",
    notNumber: "Введіть числове значення",
    notInteger: "Введіть значення більше нуля",
    numberSize: "Введіть число менше 10000000",
    dateFormat: "Будь ласка, введіть дійсну дату у формі ДД.ММ.РРРР",
    timeFormat: "Введіть дійсний час у формі ГГ:ХХ",
    dateFuture: "Введіть наявну дату в майбутньому",
    dateDifference: "Дата завершення має бути пізніше дати початку",
    timeFuture: "Введіть час у майбутньому",
    timeDifference: "Час завершення має бути пізніше часу початку",
    photoType: "Непідтримуваний формат зображення",
    fileSize: "Максимальний розмір файлу становить 1 МБ"
  },
  tags: {
    health: {
      name: "Здоров'я",
      description: "Епідеміологи, громадське здоров’я, статистики охорони здоров’я, ланцюг постачання охорони здоров’я, лікарі, медсестри, лаборанти, студенти медицини, біології та хімії, поводження з медичними відходами."
    },
    management: {
      name: "Менеджмент",
      description: "Управління та координація надзвичайних ситуацій, аварійна комунікація, маркетинг і засоби масової інформації, управління інформацією, аналіз даних, моніторинг, оцінка та звітність."
    },
    food: {
      name: "Їжа",
      description: ""
    },
    distribution: {
      name: "Дистрібуція",
      description: "Перевезення, розподіл та завантаження товарів."
    },
    education: {
      name: "Освіта",
      description: "Педагогіка, захист дітей, надання консультацій."
    },
    children: {
      name: "Діти",
      description: ""
    },
    seniors: {
      name: "Старші люди",
      description: ""
    },
    casework: {
      name: "Соціальна праця",
      description: "Психосоціальна підтримка та надання консультацій, соціальна робота."
    },
    socialHelp: {
      name: "Соціальна допомога",
      description: "Соціально-економічні та медичні послуги, антропологія, соціологія."
    },
    sewing: {
      name: "Шитво",
      description: "Пошиття медичних масок, захисних костюмів та інших допоміжних матеріалів."
    },
    communityWork: {
      name: "Комунальна праця",
      description: "Гастрономія, заготівлі, засоби для існування та турбота про місто."
    }
  },
  eventPresets: {
    psychosocialHelp: {
      name: "Психологічна допомога"
    },
    teachingChildren: {
      name: "Навчання дітей онлайн (вчителі, педагоги, працівники культури, актори)"
    },
    telephony: {
      name: "Телефонування (адміністрування та організація)"
    },
    disinfection: {
      name: "Дезінфекція (досвід роботи зі спреями та хімікатами)"
    },
    sewingDrapes: {
      name: "Пошиття медичних масок"
    },
    mobileLaboratory: {
      name: "Мобільні лабораторії (студенти-медики)"
    },
    collectionOfMedicines: {
      name: "Забір ліків в аптеці"
    },
    shoppingForSeniors: {
      name: "Покупки для літніх людей, одиноких батьків або людей на карантині"
    },
    distribution: {
      name: "Доставка (їжа, бакалія, ліки тощо) для людей похилого віку чи інших людей з обмеженими можливостями"
    },
    medicalDistribution: {
      name: "Роздача медичних та захисних матеріалів"
    },
    writingOrders: {
      name: "Написання замовлень на закупівлю продуктів першої необхідності"
    },
    foodDelivery: {
      name: "Доставка їжі та замовлень"
    },
    assistanceDelivery: {
      name: "Доставка матеріальної допомоги"
    },
    other: {
      name: "Інше"
    }
  }
}
