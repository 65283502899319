export default {
  language: "Jazyk",
  cancel: "Zrušiť",
  popup: {
    password: "Heslo",
    cancel: "Zrušiť",
    confirm: "Potvrdiť"
  },
  data: {
    myRegion: "Môj okres",
    allRegions: "Všetky okresy",
    noData: "Žiadne dáta",
  },
  errors: {
    errorTitle: "Chyba",
    back: "Spať na hlavnú stránku",
    form: "Nesprávne vyplnený formulár",
    address: "Neplatná adresa",
    atLeastOneDay: "Vyber aspoň jeden deň",
    errorText: "Ospravedlňujeme sa, ale na našej strane nastala chyba a nevedeli sme vybaviť túto požiadavku. Skúste to prosím neskôr. V prípade, že chyba pretrváva, kontaktuje nás prosím na helfni@helfni.sk."
  },
  activeEmail: {
    text: "Emailová adresa bola úspešne potvrdená. Môžeš sa",
    login: "prihlásiť",
    invalidCode: "Neplatný kód. Pre aktivovanie účtu použi odkaz v maili.",
    alreadyConfirmed: "Tvoj mail už je potvrdený, môžeš sa prihlásiť."
  },
  login: {
    confirm: "Potvrď svoju emailovu adresu pomocou odkazu v maili, ktorý sme ti poslali na tvoju emailovú adresu. Po potvrdení sa budeš môcť prihlásiť. V prípade, že email nepríde hneď, počkaj prosím zopár minút a skontroluj aj priečinok spam. Ak ti email aj tak neprišiel, kontaktuj nás prosím emailom na helfni@helfni.sk.",
    register: "Na to, aby si si mohol zobraziť dobrovoľníkov sa najprv musíš zaregistrovať a prihlásiť ako organizácia.",
    welcome: "Vitaj!",
    username: "Prihlasovacie meno alebo email",
    password: "Heslo",
    forgot: "Zabudol som heslo",
    login: "Prihlásiť sa",
    noAccount: "Nemáš konto?",
    createAccount: "Vytvor si nové",
    incorrectPassword: "Nesprávne heslo",
    userNotFound: "Nesprávne prihlasovacie meno alebo email",
    emailNotVerified: "Potvrď email pomocou odkazu, ktorý sme ti poslali na tvoju emailovú adresu, aby si sa mohol prihlásiť"
  },
  passwordRecovery: {
    recover: "Obnovenie hesla",
    enterNewPassword: "Zadaj nové heslo",
    newPassword: "Nové heslo",
    changePassword: "Zmeniť heslo",
    codeUsed: "Tento kód na obnovenie hesla už bol použitý.",
    invalidCode: "Neplatný kód",
    inputEmail: "Zadaj svoj email nižšie a ak sa zhoduje s mailom priradeným k účtu Helfni, pošleme ti naň link na obnovenie hesla",
    email: "Email",
    messageSent: "Ak bol zadaný email správny, mal by na neho prísť link na obnovenie hesla. V prípade, že email nepríde hneď, počkaj prosím zopár minút a skontroluj aj priečinok spam. Ak ti email aj tak neprišiel, kontaktuj nás prosím emailom na helfni@helfni.sk.",
    sendEmail: "Poslať obnovovací Email",
    success: "Heslo bolo úspešne zmenené. Môžeš sa",
    login: "prihlásiť"
  },
  register: {
    title: "Zaregistruj sa v HELFNI a pomáhaj!",
    accountType: {
      title: "Vyber si z dvoch typov účtov",
      volunteer: "Dobrovoľník",
      volunteerDescription: "S týmto účtom si budeš môcť vyberať ponuky od organizácií, na ktorých chceš pomáhať.",
      organisation: "Organizácia",
      organisationDescription: "S týmto účtom budeš môcť vytvárať ponuky, na ktoré sa ti môžu hlásiť ľudia ochotní pomôcť."
    },
    continue: "Pokračuj",
    registration: "Registrácia",
    back: "Naspäť",
    personalInformation: {
      title: "Osobné údaje",
      name: "Meno a priezvisko",
      email: "Email",
      region: "Môj okres",
      descriptionText: "V tejto časti napíš niečo o sebe. Či už tvoje predošle skúsenosti s dobrovoľníctvom, tvoju motiváciu helfnúť svojmu mestu alebo hocičo, čo považuješ na sebe za dôležité. Tento popis vidia organizácie, ktoré si môžu aktívne hľadať dobrovoľníkov, a preto môže zvýšiť tvoju šancu na to, aby si ťa organizácia vybrala.",
      descriptionLabel: "Popis o sebe, prípadne referencie na predošlé skúsenosti",
      skills: "Moje zručnosti, vedomosti a schopnosti"
    },
    organisation: {
      title: "Údaje o organizácii",
      name: "Názov organizácie",
      address: "Presná adresa",
      region: "Okres",
      description: "Popis organizácie",
      contactTitle: "Kontaktné údaje",
      phone: "Telefónny kontakt",
      email: "Email"
    },
    loginDetails: {
      title: "Prihlasovacie údaje",
      username: "Prihlasovacie meno",
      password: "Heslo",
      repeatPassword: "Zopakuj heslo"
    },
    consent: "Súhlasím s",
    terms: "podmienkami používania",
    register: "Zaregistrovať sa",
    errors: {
      usernameTaken: "Používateľ s týmto prihlasovacím menom už existuje",
      emailTaken: "Používateľ s týmto emailom už existuje",
      fixErrors: "Oprav všetky chyby nižšie"
    }
  },
  navbar: {
    dashboard: "Domov",
    home: "Domov",
    offers: "Ponuky",
    map: "Mapa",
    volunteers: "Dobrovoľníci",
    organisations: "Organizácie",
    logout: "Odhlásiť sa",
    login: "Prihlásenie",
    register: "Registrácia",
    profile: "Profil"
  },
  landing: {
    title: "svojmu mestu",
    subtitle: "teraz to potrebuje ešte viac ako inokedy.",
    wantToHelp: "Máš chuť pomáhať?",
    seeOffers: "Pozri si ponuky",
    howToHelp: {
      title: "Ako pomôcť s HELFNI?",
      register: "Zaregistruj sa na webe alebo v appke",
      choose: "Vyberaj si ponuky, na ktorých chceš pomáhať alebo vytváraj tieto ponuky",
      help: "Zanechaj stopu na svojom okolí",
    },
    iWantToRegister: "Chcem sa zaregistrovať",
    whoWeAre: {
      title: "Kto sme?",
      text: "{kajo} stojí za prevádzkou, údržbou a aktívnym vývojom HELFNI. Pôvodná myšlienka HELFNI pochádza od skupiny študentov z Košíc podporovaných Inštitútom pre rozvoj spoločnosti a Green Foundation."
    },
    whatWeDo: {
      title: "Čo robíme?",
      text: "{helfni} je platforma spájajúca dobrovoľníkov s dobrovoľníckymi činnosťami založená s cieľom oboznamovať o príležitostiach, kde možno pomôcť, sprostredkovať efektívnu komunikáciu medzi dobrovoľníkmi, verejnými a súkromnými organizáciami. Práve v týchto časoch sa viac ako inokedy predtým pomoc nesmierne cení."
    },
    numbers: {
      title: "Sme v tom SPOLU",
      volunteers: "dobrovoľníkov",
      organisations: "organizácií",
      offers: "ponúk na pomoc"
    },
    offerList: {
      title: "Helfni nám",
      notFound: "Nenašli sa žiadne ponuky",
      showMore: "zobraziť viac"
    },
    map: {
      title: "Kde nás môžeš nájsť ?",
      showOnMap: "zobraziť v mapách"
    },
    motto: "HELFNI - Pomáhame ti pomáhať",
    partners: "Spolupracujeme s týmito organizáciami",
    footer: {
      socialMedia: "Pozri si nás aj na sociálnych sieťach",
      disclaimer: "Informácie sprostredkované touto aplikáciou sú necenzurované. Zodpovednosť za obsah, pravdivosť a aktuálnosť informácií z profilov nesie výlučne každý registrovaný užívateľ."
    }
  },
  event: {
    back: "Naspäť",
    create: "Vytvor vlastnú ponuku",
    delete: "Vymazať ponuku",
    cancel: "Zrušiť",
    gallery: "Galéria k udalosti",
    map: "Mapa",
    errors: {
      endBeforeStart: "Čas konca je skôr ako čas začiatku",
      invalidAddress: "Nepodarilo sa rozpoznať adresu. Zadaj ju v inom tvare alebo zadaj inú adresu."
    },
    dialogs: {
      deleteTitle: "Si si istý?",
      deleteText: "Vymazaním ponuky sa ponuka vymaže z nášho systému a nikto ju už nebude vidieť, ani sa na ňu nebude môcť prihlásiť.",
      unlinkTitle: "Si si istý?",
      unlinkText: "Naozaj sa chceš odhlásiť z danej ponuky?"
    },
    form: {
      detail: "Detailný popis",
      moreInfo: "Bližšie informácie",
      when: "Kedy",
      theseDays: "Stále v tieto dni",
      next: "Najbližšie",
      where: "Kde",
      howMany: "Koľko ľudí",
      freePlaces: "Voľných miest",
      organizer: "Organizátor",
      challenge: "Ak ťa táto ponuka zaujala a rád by si na nej pomohol, neváhaj sa prihlásiť a tým upozorniť organizáciu, že prídeš pomôcť.",
      typeTitle: "Táto ponuka je",
      protected: "chránená",
      protectedTooltip: "Ak požiadaš o pripojenie na chránenú ponuku, musíš počkať na schválenie tvojej žiadosti organizátorom."
    },
    join: {
      title: "Pridať sa",
      text: "Na to, aby si sa mohol pridať na ponuku sa musíš ",
      login: "Prihlásiť",
      requestSent: "Žiadosť o pripojenie bola úspešne zaslaná",
      successful: "Prihlásenie prebehlo úspešne",
      errors: {
        notExists: "Táto ponuka už nie je aktuálna",
        alreadyJoined: "Na túto ponuku si sa už pridal(a)"
      }
    },
    cancelRequest: {
      title: "Odobrať sa",
      text: "Zrušiť žiadosť",
      full: "Naplnené",
      join: "Pridať sa",
      successful: "Odhlásenie prebehlo úspešne"
    },
    edit: {
      title: "Upraviť ponuku",
      successful: "Ponuka bola úspešne zmenená",
      errors: {
        notExists: "Táto ponuka už nie je aktuálna",
        notSpecified: "Nepodarilo sa zmeniť údaje",
        notOwn: "Nepodarilo sa zmeniť údaje, pretože túto ponuku nevlastníš",
        time: "Čas konca je skôr ako čas začiatku",
        invalidData: "Neplatný formulár"
      }
    }
  },
  eventTable: {
    search: "Hľadať",
    createOffer: "Vytvoriť ponuku",
    showPastOffers: "Zobraziť aj ponuky z minulosti",
    noOffers: "Žiadne ponuky"
  },
  linkedUsers: {
    title: "Dobrovoľníci, ktorí sa prihlásili na tvoje ponuky",
    chooseOffer: "Vyber ponuku",
    noUsers: "Na túto ponuku sa zatiaľ neprihlásili žiadny dobrovoľníci",
    all: "Všetky",
    singleOffer: "Dobrovoľníci, ktorí sa prihlásili na túto ponuku"
  },
  requests: {
    title: "Dobrovoľníci, ktorí sa chcú prihlásiť na tvoje chránene ponuky",
    chooseOffer: "Vyber ponuku",
    noOffers: "Žiadne ponuky",
    offerFull: "Táto ponuka je už naplnená, nemôžeš potvrdzovať nové žiadosti",
    freePlaces: "Počet voľných miest",
    confirm: "Potvrdiť účasť",
    decline: "Zamietnuť účasť",
    skills: "Schopnosti",
    desription: "Popis",
    send: "Odoslať",
    noRequests: "Na vybranú ponuku momentálne nie sú žiadne nové žiadosti"
  },
  eventCard: {
    offerFull: "Táto ponuka je už naplnená a nedá sa na ňu prihlásiť",
    freePlaces: "Na tejto ponuke ešte sú voľné miesta",
    protectedTooltip: "Ak požiadaš o pripojenie na chránenú ponuku, musíš počkať na schválenie tvojej žiadosti organizátorom."
  },
  eventForm: {
    basicInformation: "Základné informácie",
    offerTitle: "Názov ponuky",
    offerDescription: "Popis ponuky",
    offerType: "Typ ponuky",
    offerTags: "Zameranie ponuky",
    offerFreePlaces: "Počet voľných miest",
    offerPlace: "Miesto ponuky",
    offerPlaceTooltip: "Ak má táto ponuka presnú adresu, na ktorej sa bude konať, vyplň ju a okres bude vo väčšine prípadov pridelený automaticky. Ak nemá presnú adresu, vyplň aspoň okres.",
    offerAddress: "Adresa konania akcie",
    offerRegion: "Okres",
    offerTime: {
      title: "Čas ponuky",
      subtitle: "Moja ponuka je",
      oneDay: "Jednodňová",
      oneDayDescription: "Jednodňová ponuka je taká, ktorá sa bude konať len v jeden konkrétny deň.",
      multiDay: "Viacdňová",
      multiDayDescription: "Viacdňová ponuka sa koná jednorazovo, ale trvá viac dní.",
      repeating: "Opakovaná",
      repeatingDescription: "Opakovaná ponuka sa koná v pravidelných intervaloch, napríklad každý týždeň vo štvrtok.",
      longTerm: "Dlhodobá",
      longTermDescription: "Dlhodobá ponuka nemá presne zadaný čas vykonávania, ale tvrá dlhšiu dobu.",
      date: "Dátum konania",
      startDate: "Dátum začiatku",
      endDate: "Dátum konca",
      chooseDays: "Vyber dni, v ktorých sa ponuka koná",
      everyDay: "Každý deň",
      startTime: "Čas začiatku",
      endTime: "Predpokladaný čas konca",
      allDay: "Ponuka sa koná počas celého dňa",
      protectedTooltip: "Ak je ponuka chránená, budeš musieť potvrdiť každú žiadosť dobrovoľníka o účasť na tejto akcii.",
      create: "Vytvoriť",
      change: "Uložiť zmeny",
      submit: "Odoslať"
    }
  },
  ozCard: {
    description: "Popis",
    profile: "Profil"
  },
  inviteDialog: {
    invitation: "Pozvánka",
    invitationText: "Pozvi tohto dobrovoľníka na jednu zo svojich voľných ponúk",
    notFound: "Nenašli sa žiadne ponuky",
    cancel: "Zrušiť",
    confirm: "Potvrdiť",
    error: "Vyskytla sa chyba, nie je možné pozvať dobrovoľníkov."
  },
  userCard: {
    description: "Popis",
    profile: "Profil",
    invite: "Pozvať",
    invitationError: "Nepodarilo sa poslať pozvánku."
  },
  offer: {
    longTerm: "Dlhodobá ponuka",
    allDay: "Celý deň",
    joined: "Prihlásených",
    protected: "Chránená ponuka",
    deleted: "Táto ponuka bola vymazaná."
  },
  filter: {
    hide: "Schovať filtre",
    show: "Zobraziť filtre",
    clear: "Vymaž filtre",
    region: "Zadaj okres, v ktorom chceš ponuky: ",
    regionLabel: "Okres",
    type: "Zameranie ponuky",
    title: "Vyhľadať ponuku podľa názvu: ",
    titleLabel: "Názov ponuky",
    date: "Vyber si dni, v ktorých chceš ponuky: ",
    dateLabel: "Dátum akcie",
    dateText: "Hľadať v konkrétne dni",
    longTerm: "Zobrazovať dlhodobé ponuky",
    all: "Všetky ponuky: ",
    nothingFound: "Nenašli sa žiadne ponuky"
  },
  deleteAccount: {
    message: "Tvoj účet bude automaticky vymazaný, pokiaľ do troch dní toto vymazanie nezrušíš pomocou odkazu, ktorý nájdeš v maili.",
    errors: {
      checksumFailed: "Neplatný kód. Pre vymazanie účtu použi odkaz v maili.",
      invalidCode: "Tento kód už bol použitý, účet už je pripravený na vymazanie, pokiaľ toto vymazanie nezrušíš."
    }
  },
  deleteAccountCancel: {
    message: "Vymazanie účtu bolo úspešne zrušené.",
    errors: {
      any: "Neplatný alebo už použitý kód. Zruš vymazanie účtu pomocou odkazu v maili, ktorý ti prišiel alebo pomocou odkazu v aplikácii."
    }
  },
  emailChange: {
    message: "Email k tvojmu účtu bol úspešne zmenený.",
    errors: {
      checksumFailed: "Neplatný kód. Pre potvrdenie zmeny emailu použi odkaz v maili.",
      invalidCode: "Tento kód už bol použitý, email k tvojmu účtu by mal byť zmenený. V opačnom prípade nás kontaktuj na emailovej adrese helfni@helfni.sk."
    }
  },
  emailChangeRevert: {
    message: "Zmena emailu bola úspešne vrátená späť.",
    errors: {
      checksumFailed: "Neplatný kód. Pre vrátenie zmeny emailu použi odkaz v maili.",
      invalidCode: "Tento kód už bol použitý, zmena emailu k tvojmu účtu by mala byť vrátená späť. V opačnom prípade nás kontaktuj na emailovej adrese helfni@helfni.sk."
    }
  },
  mailSubscription: {
    cancelMessage: "Úspešné odhlásenia sa z dostávania emailov."
  },
  dashboard: {
    created: "Ponuka úspešne vytvorená",
    offers: "Tvoje ponuky"
  },
  appHome: {
    recommended: "Odporúčané ponuky: ",
    next: "Moje najbližšie ponuky: ",
    none: "Aktuálne nie si prihlásený na žiadne ponuky.",
    search: "Vyhľadať ponuky",
    awaits: "Čaká na schválenie: "
  },
  map: {
    filters: {
      hide: "Schovať filtre",
      show: "Zobraziť filtre",
      clear: "Vymaž filtre"
    },
    region: {
      label: "Okres",
      text: "Zadaj okres, v ktorom chceš ponuky: "
    },
    tag: {
      label: "Zameranie ponuky"
    },
    name: {
      label: "Názov ponuky",
      text: "Vyhľadať ponuku podľa názvu: "
    },
    date: {
      label: "Dátum akcie",
      textCheckbox: "Hľadať v konkrétne dni",
      textFilter: "Vyber si dni, v ktorých chceš ponuky: "
    },
    longTerm: {
      label: "Zobrazovať dlhodobé ponuky"
    },
    marker: {
      moreInfo: "Klikni pre viac info",
      tag: {
        free: "Potrebuje dobrovoľníkov",
        linked: "Dobrovoľníci nie sú potrební",
        requested: "Požadované o pripojenie"
      }
    }
  },
  organisations: {
    filters: {
      hide: "Schovať filtre",
      show: "Zobraziť filtre",
      clear: "Vymaž filtre"
    },
    region: {
      label: "Okres",
      text: "Zadaj okres, v ktorom hľadáš organizácie: "
    },
    nameOrDescription: {
      label: "Názov alebo popis",
      text: "Vyhľadať organizácie podľa názvu alebo popisu: "
    },
    notFound: {
      text: "Nenašli sa žiadne organizácie"
    }
  },
  profile: {
    title: "Môj profil",
    type: {
      organization: "Organizácia",
      volunteer: "Dobrovoľník"
    },
    delete: {
      label: "Si si istý?",
      text: "Po zadaní hesla ti pošleme email s odkazom, pomocou ktorého budeš môcť vymazanie účtu potvrdiť. Po 3 dňoch po potvrdení bude tvoj účet permanentne vymazaný spolu so všetkými tvojimi ponukami. V prípade, že email nepríde hneď, počkaj prosím zopár minút a skontroluj aj priečinok spam. Ak ti email aj tak neprišiel, kontaktuj nás prosím emailom na helfni@helfni.sk.",
    },
    changePassword: {
      label: "Zmena hesla",
      text: "Zadaj svoje staré heslo a potom nové heslo.",
      oldPassword: "Staré heslo",
      newPassword: "Nové heslo",
      repeatPassword: "Zopakuj nové heslo",
      actions: {
        cancel: "Zrušiť",
        confirm: "Potvrdiť"
      }
    },
    changeEmail: {
      label: "Zmena emailu",
      text: "Zadaj heslo a nový email. Na zadaný email ti pošleme kód na potvrdenie emailu a dokončenie zmeny. Ak by si chcel túto zmenu vrátiť, je to možne do 7 dní kliknutím na link, ktorý dostaneš na svoj starý email.",
      password: "Heslo",
      newEmail: "Nový email",
      actions: {
        cancel: "Zrušiť",
        confirm: "Potvrdiť"
      }
    },
    info: {
      photo: "Nahraj fotku",
      user: {
        label: "Dobrovoľník",
        name: "Meno",
        region: "Okres",
        username: "Prihlasovacie meno",
        email: "Email"
      },
      organisation: {
        label: "Organizácia",
        name: "Názov organizácie",
        username: "Prihlasovacie meno",
        email: "Email",
        phone: "Telefón",
        address: "Adresa"
      },
      description: "Popis",
    },
    edit: {
      password: "Zmeniť heslo",
      email: "Zmeniť email",
      delete: "Vymazať účet",
      editProfile: "Upraviť profil",
      save: "Uložiť zmeny",
      cancel: "Zrušiť"
    },
    unsubscribe: "Nechcem dostávať maily o nových ponukách",
    messages: {
      notLoggedIn: "Na to, aby si si pozeral profily dobrovoľníkov musíš byť prihlásený.",
      notFound: "Profil sa nenašiel",
      photoInvalidPicture: "Nahraj platný obrázok ako profilovú fotku",
      photoInvalidFile: "Nahraj platný súbor",
      photoFileTooBig: "Príliš veľká profilová fotka, nahraj menší súbor.",
      wrongPassword: "Nesprávne heslo",
      passwordChanged: "Heslo úspešne zmenené",
      emailChangeSent: "Email na potvrdenie zmeny bol odoslaný",
      subscriptionChanged: "Posielanie mailov úspešne zmenené"
    }
  },
  users: {
    inviteSent: "Pozvánka úspešne odoslaná",
    filters: {
      hide: "Schovať filtre",
      show: "Zobraziť filtre",
      clear: "Vymaž filtre"
    },
    region: {
      label: "Okres",
      text: "Zadaj okres, v ktorom hľadáš dobrovoľníkov: "
    },
    tags: {
      label: "Schopnosti dobrovoľníkov"
    },
    nameOrDescription: {
      label: "Meno alebo popis",
      text: "Vyhľadať dobrovoľíkov podľa mena alebo popisu: "
    },
    notFound: {
      text: "Nenašli sa žiadny dobrovoľníci"
    }
  },
  browserNotSupported: {
    label: "Ľutujeme, ale tento prehliadač nie je podporovaný.",
    text: "Použi, prosím, jeden z novších prehliadačov alebo našu mobilnú aplikáciu."
  },
  warningDeleteAcc: {
    label: "VYMAZANÝ ÚČET",
    text: " tento účet a všetky s ním spojené údaje budú do troch dní vymazané. Vymazanie je možné zrušiť ",
    link: "týmto odkazom."
  },
  routes: {
    landing: "Helfni",
    home: "Domov",
    map: "Mapa ponúk",
    users: "Zoznam dobrovoľníkov",
    organisations: "Zoznam organizácií",
    eventsList: "Zoznam ponúk",
    eventCreate: "Vytvorenie ponuky",
    profile: "Profil",
    dashboard: "Domov",
    login: "Prihlásenie",
    register: "Registrácia",
    activateEmail: "Aktivácia emailu",
    recoverPassword: "Obnovenie hesla",
    deleteAccount: "Vymazanie účtu",
    changeEmail: "Zmena emailu",
    logout: "Odhlásenie",
    terms: "Podmienky používania",
    unsubscribe: "Nastavenia mailu",
    notSupported: "Nepodporovaný prehliadač"
  },
  inputRules: {
    required: "Toto pole je povinné",
    minLength6: "Minimálna dĺžka je 6 znakov",
    maxLength1000: "Maximálna dĺžka je 1000 znakov",
    maxLength100: "Maximálna dĺžka je 100 znakov",
    maxLength80: "Maximálna dĺžka je 80 znakov",
    maxLength64: "Maximálna dĺžka je 64 znakov",
    maxLength32: "Maximálna dĺžka je 32 znakov",
    usernameAt: "Prihlasovacie meno nesmie obsahovať @",
    wrongEmail: "Nesprávna emailová adresa",
    samePassword: "Heslá sa nezhodujú",
    wrongPhone: "Zadaj platné telefónne číslo",
    notNumber: "Zadaj číselnú hodnotu",
    notInteger: "Zadaj kladnú hodnotu",
    numberSize: "Zadaj číslo menšie ako 10000000",
    dateFormat: "Zadaj platný dátum v tvare DD.MM.RRRR",
    timeFormat: "Zadaj platný čas v tvare HH:MM",
    dateFuture: "Zadaj existujúci dátum v budúcnosti",
    dateDifference: "Dátum konca musí byť neskôr ako dátum začiatku",
    timeFuture: "Zadaj čas v budúcnosti",
    timeDifference: "Čas konca musí byť neskôr ako čas začiatku",
    photoType: "Nepodporovaný formát obrázka",
    fileSize: "Maximálna veľkosť súboru je 1 MB"
  },
  tags: {
    health: {
      name: "Zdravie",
      description: "Epidemiológovia, verejné zdravie, štatistici zdravia, zdravotnícky dodávateľský reťazec, lekári, zdravotné sestry, laboratórni technici, študenti medicíny, biológie a chémie, nakladanie s lekárskym odpadom."
    },
    management: {
      name: "Manažment",
      description: "Núdzové riadenie a koordinácia, komunikácia v prípade núdze, marketing a práca s médiami, správa informácií, analýza údajov, monitorovanie, hodnotenie a podávanie správ."
    },
    food: {
      name: "Jedlo",
      description: ""
    },
    distribution: {
      name: "Distribúcia",
      description: "Doprava, rozvoz a nakladanie tovaru."
    },
    education: {
      name: "Vzdelávanie",
      description: "Pedagogika, ochrana detí, poradenstvo."
    },
    children: {
      name: "Deti",
      description: ""
    },
    seniors: {
      name: "Starší ľudia",
      description: ""
    },
    casework: {
      name: "Sociálna práca",
      description: "Psychosociálna podpora a poradenstvo a sociálna práca."
    },
    socialHelp: {
      name: "Sociálna pomoc",
      description: "Sociálno-ekonomické a zdravotné služby, antropológia, sociológia."
    },
    sewing: {
      name: "Šitie",
      description: "Šitie rúšok, ochranných oblekov a iného pomocného materiálu."
    },
    communityWork: {
      name: "Komunitná práca",
      description: "Gastronómia, obstarávanie tovaru, živobytie a starostlivosť o mesto."
    }
  },
  eventPresets: {
    psychosocialHelp: {
      name: "Psychosociálna pomoc"
    },
    teachingChildren: {
      name: "Vyučovanie detí online (učitelia, pedagógovia, kultúrni referenti, herci)"
    },
    telephony: {
      name: "Telefonovanie (administrácia a organizácia)"
    },
    disinfection: {
      name: "Dezinfikovanie (skúsenosti s postrekmi a chemickými látkami)"
    },
    sewingDrapes: {
      name: "Šitie rúšok"
    },
    mobileLaboratory: {
      name: "Mobilné laboratória (študenti zdravotníctva)"
    },
    collectionOfMedicines: {
      name: "Vyzdvihnutie liekov v lekárni"
    },
    shoppingForSeniors: {
      name: "Nákup pre seniorov, osamelých rodičov alebo ľudí v karanténe"
    },
    distribution: {
      name: "Rozvoz (potravín, stravy, liekov a pod.) pre seniorov alebo inak znevýhodnených ľudí"
    },
    medicalDistribution: {
      name: "Distribúcia zdravotníckeho a ochranného materiálu"
    },
    writingOrders: {
      name: "Spisovanie objednávok na kúpu nevyhnutných potravín"
    },
    foodDelivery: {
      name: "Roznášanie jedla a objednávok"
    },
    assistanceDelivery: {
      name: "Rozvoz materiálnej pomoci"
    },
    other: {
      name: "Iné"
    }
  }
}
