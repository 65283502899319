import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md'
    },
    theme: {
      themes: {
        light: {
          primary: '#FF8140'
        }
      },
      options: {
        customProperties: true
      }
    },
    breakpoint: {
      thresholds: {
        xs: 600,
        sm: 1160,
        md: 1365,
        lg: 1921
      }
    },
});
