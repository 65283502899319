<template>
  <v-app>
    <v-content class="grey lighten-4">
      <div
        class="ma-10 display-1 font-weight-bold pb-5"
        v-if="$store.state.deleteDate"
      >
        <v-icon class="red--text mr-3" large>warning</v-icon
        ><span class="red--text">VYMAZANÝ ÚČET</span
        ><v-icon class="red--text mx-3" large>warning</v-icon> tento účet a
        všetky s ním spojené údaje budú do troch dní vymazané. Vymazanie je
        možné zrušiť
        <router-link :to="{ name: 'cancelDelete' }">týmto odkazom.</router-link>
      </div>
      <router-view v-title="title($route.meta)"></router-view>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data() {
    return {};
  },
  created() {},
  methods: {
    title(meta) {
      return (meta && meta.title && this.$t(meta.title)) || "Helfni";
    },
  },
};
</script>
<style lang="scss">
a {
  text-decoration: none !important;
}
.plain:before {
  display: none;
}
</style>
