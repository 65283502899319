import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import { store } from './store/store'
import "core-js/stable";
import "regenerator-runtime/runtime";
import VueGtag from "vue-gtag";

import VueI18n from 'vue-i18n';

import en from './localization/en';
import sk from './localization/sk';
import ua from './localization/ua';
import pl from './localization/pl';
import de from './localization/de';
//import fi from './localization/fi';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// we don't use default markers, so in order to fix this bug https://github.com/vue-leaflet/Vue2Leaflet/issues/593
// we just cut it
delete L.Icon.Default.prototype._getIconUrl;


const messages = {
  'en-EN': en,
  'sk-SK': sk,
  'uk-UA': ua,
  'pl-PL': pl,
  'de-DE': de,
  //'fi-FI': fi,
}

if (process.env.VUE_APP_MODE != 'development') {
  Vue.use(VueGtag, {
    config: { id: "UA-163767756-1" }
  }, router);
}

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en-EN',
  messages,
  warnHtmlInMessage: 'off',
});

Vue.config.productionTip = false

Vue.directive('title', {
  inserted: (el, binding) => document.title = binding.value,
  update: (el, binding) => document.title = binding.value
});

new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')


// hot reloading setup for localization files
if (module.hot) {
  module.hot.accept(
    [
      './localization/en',
      './localization/sk',
      './localization/ua',
      './localization/pl',
      './localization/de',
      //'./localization/fi',
    ],
    function () {
      i18n.setLocaleMessage('en-EN', require('./localization/en').default)
      i18n.setLocaleMessage('sk-SK', require('./localization/sk').default)
      i18n.setLocaleMessage('uk-UA', require('./localization/ua').default)
      i18n.setLocaleMessage('pl-PL', require('./localization/pl').default)
      i18n.setLocaleMessage('de-DE', require('./localization/de').default)
      //i18n.setLocaleMessage('fi-FI', require('./localization/fi').default)
    })
}
