import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import regions from './regions'

Vue.use(Vuex)
axios.defaults.baseURL = process.env.VUE_APP_API_URL

export const store = new Vuex.Store({
  state: {
    google: null,
    pastEvents: false,
    showFilters: false,
    daysFilter: {
      'Pondelok': '1',
      'Utorok': '2',
      'Streda': '3',
      'Štvrtok': '4',
      'Piatok': '5',
      'Sobota': '6',
      'Nedeľa': '0'
    },
    weekDaysObject: {
      1: 'Pondelok',
      2: 'Utorok',
      3: 'Streda',
      4: 'Štvrtok',
      5: 'Piatok',
      6: 'Sobota',
      0: 'Nedeľa'
    },
    showPassword: false,
    filteredDays: ['1', '2', '3', '4', '5', '6', '0'],
    filteredDateRange: [],
    showFilteredDate: false,
    filteredText: '',
    filteredRegion: 'data.allRegions',
    filteredTags: [],
    showLongtime: true,
    eventsPage: 1,
    usersPage: 1,
    ozPage: 1,
    deleteDate: null,
    token: localStorage.getItem('access_token') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    loggedInFlag: localStorage.getItem('loggedInFlag') || null,
    photo: localStorage.getItem('photo') || null,
    name: localStorage.getItem('name') || null,
    region: localStorage.getItem('region') || null,
    loggedInId: localStorage.getItem('loggedInId') || null,
    tags: {
      health: { icon: 'healing', isUser: true },
      management: { icon: 'person', isUser: true },
      food: { icon: 'local_dining' },
      distribution: { icon: 'local_shipping', isUser: true },
      education: { icon: 'school', isUser: true },
      children: { icon: 'child_friendly' },
      seniors: { icon: 'emoji_people' },
      casework: { icon: 'group', isUser: true },
      socialHelp: { icon: 'face', isUser: true },
      sewing: { icon: 'gesture', isUser: true },
      communityWork: { icon: 'home', isUser: true }
    },
    eventPresets: {
      psychosocialHelp: ["socialHelp"],
      teachingChildren: ["education", "children"],
      telephony: ["management"],
      disinfection: ["health"],
      sewingDrapes: ["health", "sewing"],
      mobileLaboratory: ["health"],
      collectionOfMedicines: ["distribution"],
      shoppingForSeniors: ["distribution"],
      distribution: ["distribution", "seniors"],
      medicalDistribution: ["health", "distribution"],
      writingOrders: ["management", "food"],
      foodDelivery: ["food", "distribution"],
      assistanceDelivery: ["distribution"],
      other: []
    },
    regions
  },
  getters: {
    loggedIn(state) {
      return state.token !== null
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    destroyToken(state) {
      state.token = null
    },
    setRefreshToken(state, token) {
      state.refreshToken = token
    },
    destroyRefreshToken(state) {
      state.refreshToken = null
    },
    setLoggedInFlag(state, flag) {
      state.loggedInFlag = flag
    },
    destroyLoggedInFlag(state) {
      state.loggedInFlag = null
    },
    setPhoto(state, photo) {
      state.photo = photo
    },
    destroyPhoto(state) {
      state.photo = null
    },
    setName(state, name) {
      state.name = name
    },
    destroyName(state) {
      state.name = null
    },
    setRegion(state, region) {
      state.region = region
    },
    destroyRegion(state) {
      state.region = null
    },
    niecoIneAkoSetloggedInId(state, id) {
      state.loggedInId = id
    },
    destroyloggedInId(state) {
      state.loggedInId = null
    },
    setDeleteDate(state, date) {
      state.deleteDate = date
    },
    destroyDeleteDate(state) {
      state.deleteDate = null
    },
    switchPassword(state) {
      state.showPassword = !state.showPassword
    }
  },
  actions: {
    register(context, data) {
      return new Promise((resolve, reject) => {
        let region = parseInt(context.state.regions[data.region].code)
        var data_to_post = {
          username: data.username,
          email: data.email,
          password: data.password,
          flag: parseInt(data.flag),
          desc: data.desc,
          tags: data.tags,
          region: region
        }
        if (data.flag == "1") {
          let telephone = ''
          if (data.telephone) {
            if (data.telephone.startsWith('0')) {
              telephone = "+421" + data.telephone.substring(1, data.telephone)
            }
            else {
              telephone = data.telephone
            }
          } else {
            telephone = data.telephone
          }
          data_to_post = {
            ...data_to_post,
            title: data.name,
            address: data.address,
            telephone: telephone,
          }
        }
        else if (data.flag == "2") {
          data_to_post = {
            ...data_to_post,
            //birth: data.birth,
            name: data.name,
          }
        }
        axios.post('/register', data_to_post)
          .then(response => {
            if (response.data.message === 'Account created successfully') {
              resolve(response)
            } else {
              reject(response.data.message)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    destroyToken(context) {
      if (context.getters.loggedIn) {
        axios.defaults.headers.common['auth-token'] = '';
        localStorage.removeItem('access_token')
        context.commit('destroyToken')
        localStorage.removeItem('refreshToken')
        context.commit('destroyRefreshToken')
        localStorage.removeItem('loggedInFlag')
        context.commit('destroyLoggedInFlag')
        localStorage.removeItem('photo')
        context.commit('destroyPhoto')
        localStorage.removeItem('name')
        context.commit('destroyName')
        localStorage.removeItem('region')
        context.commit('destroyRegion')
        localStorage.removeItem('loggedInId')
        context.commit('destroyloggedInId')
        context.commit('destroyDeleteDate')
      }
    },
    retrieveToken(context, credentials) {
      return new Promise((resolve, reject) => {
        axios.post('/login', {
          user: credentials.username,
          password: credentials.password,
        })
          .then(response => {
            if (response.data.message === 'success') {
              const token = response.data.token
              axios.defaults.headers.common['auth-token'] = token;
              localStorage.setItem('access_token', token)
              context.commit('setToken', token)
              const refreshToken = response.data.refreshToken
              localStorage.setItem('refreshToken', refreshToken)
              context.commit('setRefreshToken', refreshToken)
              const flag = response.data.flag
              localStorage.setItem('loggedInFlag', flag)
              context.commit('setLoggedInFlag', flag)
              const id = response.data.id
              localStorage.setItem('loggedInId', id)
              context.commit('niecoIneAkoSetloggedInId', id)
              resolve(response)
            } else {
              reject(response.data.message)
            }
          })
          .catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
              reject(error.response.data.message)
            } else {
              reject(error)
            }
          })
      })
    },
    REFRESH_TOKEN(context) {
      return new Promise((resolve, reject) => {
        axios
          .post('refresh_token', { authToken: store.state.token, refreshToken: store.state.refreshToken })
          .then(response => {
            const token = response.data.token
            localStorage.setItem('access_token', token)
            context.commit('setToken', token)
            axios.defaults.headers.common['auth-token'] = store.state.token;
            resolve(response.data.token);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
})
